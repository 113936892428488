import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import translate from '@/helpers/traslations';
import { setVersion } from '@/redux/actionTypes';
import { HStack, Text, View } from '@gluestack-ui/themed';
import { InfoOutline, Rotate } from '@/assets/icons/Icons';
import MaterialCommunity from '../Icons/MaterialCommunity';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import MainCta from '../../atoms/MainCta';

function NewVersion() {
  const dispatch = useAppDispatch();

  const newVersion = useAppSelector((state: RootState) => state.settings.newVersion);

  const handleUpdate = React.useCallback(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        // Aquí, 'registration' es una instancia única de ServiceWorkerRegistration
        if (registration && registration.waiting) {
          // Envía un mensaje al service worker para que se active
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          window.location.reload();
          dispatch(setVersion(false));
        }
      });
    } else {
      window.location.reload();
    }
    dispatch(setVersion(false));
  }, [newVersion]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          justifyContent: 'space-between',
          gap: 5,
          width: '100%',
          paddingHorizontal: 10,
          paddingVertical: 10
        }
      }),
    []
  );
  return (
    <HStack bg="$info" style={styles.container} alignItems="center">
      <HStack alignItems="center" space="xs" flexBasis="60%">
        <MaterialCommunity icon={InfoOutline} size="sm" color="$blanco" />
        <Text lineBreakMode="clip">{translate('newVersionAvaliable')}</Text>
      </HStack>

      <MainCta
        onPress={handleUpdate}
        icon={Rotate}
        labelId="actions.reload"
        variant="outline"
        buttonSize="xs"
      />
    </HStack>
  );
}

export default React.memo(NewVersion);
