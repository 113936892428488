import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import translate from '@/helpers/traslations';
import { TText, TView } from '../themed';

function EmptySearchContact() {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        flexRow: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingVertical: 8,
          paddingHorizontal: 16,
          gap: 16
        }
      }),
    []
  );
  return (
    <TView style={styles.flexRow}>
      <TText size="sm">{translate('messages.noContactsForThisSearch')}</TText>
    </TView>
  );
}

export default React.memo(EmptySearchContact);
