import { DimensionValue, ViewStyle } from 'react-native';
import React, { ComponentType, ElementType, useMemo } from 'react';

import {
  DrawerContentComponentProps,
  DrawerNavigationOptions,
  createDrawerNavigator
} from '@react-navigation/drawer';

import { RootState, useAppSelector } from '@/redux/store';
import Fallback from '@/screens/Fallback';
import colors from '../../../config/colors';
import { HEIGHT_HEADER_LAYOUT, TABLET_SCREEN } from '@/utils/globales';
import { SettingsStackParams } from '../interfaces';
import { Routes } from '../routes';
import { Channel } from '@/types/typesRedux/interfaces';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { TView } from '@/components/themed';

/**
 * CustomDrawerComponent is a wrapper around the React Navigation Drawer to provide
 * a more customizable and flexible drawer component for the application. It allows
 * various configurations like fixed sidebar, custom styling, etc.
 *
 * @component
 */

const Drawer = createDrawerNavigator<SettingsStackParams>();

export type RouteDrawer = {
  id: string;
  name: any;
  component: ComponentType<any>;
  options: Record<string, any>;
  headerComponent?: () => JSX.Element;
  initialParams?: Record<string, any>;
};

type CustomDrawerComponentProps = {
  DrawerSidebar: ElementType<DrawerContentComponentProps>;
  routes: RouteDrawer[];
  drawePermanent?: boolean;
  maxWidthSidebar?: DimensionValue;
  borderTopLeft?: number;
  header?: () => JSX.Element | undefined;
  initialRouteName?: string | null;
};

const CustomDrawerComponent: React.FC<CustomDrawerComponentProps> = ({
  DrawerSidebar,
  routes,
  drawePermanent = true,
  maxWidthSidebar = 175,
  header = undefined,
  borderTopLeft = 0,
  initialRouteName = null
}) => {
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const channels = useAppSelector((state: RootState) => state.user.channels);
  const conversationId = useAppSelector((state: RootState) => state.conversations.id_conversation);

  const channelsAvaliables = useMemo(
    () => channels?.filter((item: Channel) => item?.available),
    [channels]
  );

  const drawerStyle: ViewStyle = useMemo(
    () => ({
      backgroundColor: colors[themeMode]?.background,
      maxWidth: maxWidthSidebar,
      borderRightWidth: 0,
      borderRightColor: colors[themeMode]?.transparent,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: maxWidthSidebar
    }),
    [themeMode, maxWidthSidebar]
  );

  const sceneContainerStyle = useMemo(
    () => ({
      backgroundColor: colors[themeMode]?.backgroundNavigationPxsol
    }),
    [themeMode]
  );

  const headerStyle = useMemo(
    () => ({
      backgroundColor: colors[themeMode]?.background,
      borderBottomWidth: 0,
      height: HEIGHT_HEADER_LAYOUT
    }),
    [themeMode]
  );

  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  const screenOptions: DrawerNavigationOptions = useMemo(() => {
    return {
      headerShown: true,
      swipeEnabled: true,
      title: 'Conversaciones',
      header: isPc ? header : undefined,
      drawerType: drawePermanent ? 'permanent' : 'front',
      drawerStyle,
      sceneContainerStyle,
      headerLeft: drawePermanent ? () => null : undefined,
      headerStyle
    };
  }, [isPc, header, drawePermanent, drawerStyle, sceneContainerStyle, headerStyle]);

  return (
    <TView
      bgColor="backgroundNavigationPxsol"
      style={{
        flex: 1,
        borderTopLeftRadius: isPc ? borderTopLeft : 0,
        overflow: 'hidden',
        width: '100%'
      }}
    >
      <Drawer.Navigator
        initialRouteName={initialRouteName ?? routes[0]?.name}
        screenOptions={screenOptions}
        drawerContent={(props) => {
          const memoProps = useMemo(() => props, [props]);
          return <DrawerSidebar {...memoProps} />;
        }}
      >
        {routes?.map((route) =>
          // validar si la ruta tiene esta en el contrato, si no esta no la renderiza
          route.name === Routes.clientes && channelsAvaliables?.length === 0 ? (
            <Drawer.Screen
              key={route.id}
              name={route.name}
              component={Fallback}
              initialParams={route.initialParams}
            />
          ) : (
            <Drawer.Screen
              key={route.id}
              name={route.name}
              component={route.component}
              initialParams={{ ...route.initialParams, id: conversationId }}
              options={{
                ...route.options
              }}
            />
          )
        )}
      </Drawer.Navigator>
    </TView>
  );
};
/**
 * Type definition for the properties that the CustomDrawerComponent expects.
 *
 * @property {ElementType<DrawerContentComponentProps>} DrawerSidebar - The sidebar component to render inside the drawer.
 * @property {RouteDrawer[]} routes - The list of routes/screens to render inside the drawer navigator.
 * @property {boolean} [drawePermanent=true] - Determines if the drawer should always be visible.
 * @property {number|string} [maxWidthSidebar=250] - The maximum width of the drawer sidebar ej: 100 | '100%'.
 * @property {string} [backgroundSidebarApp] - The background color of the sidebar.
 */
export default React.memo(CustomDrawerComponent, (prevProps, nextProps) => {
  return (
    prevProps.routes === nextProps.routes &&
    prevProps.initialRouteName === nextProps.initialRouteName &&
    prevProps.header === nextProps.header &&
    prevProps.borderTopLeft === nextProps.borderTopLeft &&
    prevProps.maxWidthSidebar === nextProps.maxWidthSidebar &&
    prevProps.drawePermanent === nextProps.drawePermanent
  );
});
