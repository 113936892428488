import React from 'react';
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger,
  Accordion
} from '@gluestack-ui/themed';
import { ChevronDown, ChevronUp } from '@/assets/icons/Icons';
import i18n from '@/providers/Localization';
import MaterialCommunity from '../components/Icons/MaterialCommunity';
import { AcordionCustomProps } from './interfaces';
import { THView, TText } from '@/components/themed';

function AcordionCustom({
  defaultOpen,
  idString,
  children,
  iconLeft,
  rightElement,
  middleElement,
  bgColor,
  tranlate = true,
  collapsed = false,
  sx,
  titleBold,
  titleSize,
  mbItem = '$2',
  iconSize = 'sm'
}: AcordionCustomProps) {
  return (
    <Accordion
      key={idString}
      width="$full"
      bg={bgColor ?? 'transparent'}
      size="md"
      defaultValue={defaultOpen ? [idString] : []}
      variant="filled"
      type="single"
      isDisabled={false}
      sx={sx}
    >
      <AccordionItem mb={mbItem} padding="$0" value={idString}>
        <AccordionHeader padding="$0">
          <AccordionTrigger
            padding="$0"
            sx={{
              paddingVertical: 4,
              paddingLeft: 4,
              borderRadius: 8,
              _web: {
                ':hover': {
                  backgroundColor: '$backgroundActive'
                }
              }
            }}
          >
            {(props) => {
              const { isExpanded } = props;
              return (
                <THView
                  bgColor="transparent"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  {!collapsed && (
                    <THView
                      bgColor="transparent"
                      style={{
                        gap: 8,
                        alignItems: 'center',
                        flexBasis: '90%',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {iconLeft && <MaterialCommunity icon={iconLeft} size="sm" />}
                      <TText
                        size={titleSize ?? null}
                        style={{
                          fontWeight: titleBold ? 'bold' : 'normal'
                        }}
                        lineBreakMode="clip"
                      >
                        {tranlate ? i18n.t(idString) : idString}
                      </TText>
                      {middleElement && middleElement}
                    </THView>
                  )}
                  <THView
                    bgColor="transparent"
                    style={{
                      flexBasis: !collapsed ? '10%' : '100%',
                      gap: 8,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {isExpanded ? (
                      <MaterialCommunity icon={ChevronUp} size={iconSize} />
                    ) : (
                      <MaterialCommunity icon={ChevronDown} size={iconSize} />
                    )}
                    {rightElement && rightElement}
                  </THView>
                </THView>
              );
            }}
          </AccordionTrigger>
        </AccordionHeader>
        <AccordionContent w="$full" padding="$0">
          {children}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

export default React.memo(AcordionCustom);
