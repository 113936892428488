import React, { useEffect, useMemo, useState } from 'react';
import InputTextForm from '../form/InputTextForm';
import MainCta from '@/atoms/MainCta';
import { IconReloadList, IconSave } from '@/assets/icons/Icons';
import SectionFrom from '../partials/automatizations/SectionFrom';
import { Routes } from '@/routers/routes';
import {
  ConditionsWorkflow,
  PropertyConditions,
  PropertyType,
  TypeConditions
} from '@/types/enums/workflows';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store/index';
import {
  setCreatingWorkflow,
  setCustomPropietyOfWorkflow,
  setNewWorkflow
} from '@/redux/actionTypes/index';
import MenuCheckbox from '../partials/automatizations/MenuCheckbox';
import MenuTrigger from '../partials/automatizations/MenuTrigger';
import LayoutForm from '../partials/automatizations/LayoutForm';
import { createWorkflow, editWorkflow } from '@/redux/slices/automatization/requests';
import { findLabelRangeDate, getPlatforms } from '@/redux/slices/automatization/functions';

import { Condition, Workflow } from './interface';
import {
  clearAllRangeDate,
  setDatesRanges,
  updateWorkflow
} from '@/redux/slices/automatization/index';

import { IS_WEB_PC } from '@/utils/functions';
import { useLinkss } from '@/providers/LinkProvider';
import FirstMessageCheckbox from '../partials/automatizations/FirstMessageCheckbox';
import MenuTemplate from '../partials/automatizations/MenuTemplate';
import { getTemplatesWorkflows } from '@/redux/slices/templates/requests';
import MenuBot from '../partials/automatizations/MenuBot';
import ItemRangeDate from '../partials/automatizations/ItemRangeDate';
import translate from '@/helpers/traslations';
import { setMenuRigth } from '@/redux/slices/theme';
import { ThemedScrollView, THView, TVView } from '../themed';

const FormAutomatization = (routeForm) => {
  const dispatch = useAppDispatch();
  const newWorkflow = useAppSelector((state: RootState) => state.automatization.newWorkflows);
  const workflows = useAppSelector((state: RootState) => state.automatization?.workflows);

  const idStringWorkflow = useAppSelector(
    (state: RootState) => state.automatization.idStringWorkflow
  );
  const rangeDate = useAppSelector((state: RootState) => state.automatization.rangeDate);
  const [creating, setCreating] = useState(false);

  const { showToast } = useLinkss();

  const itemId = useMemo(
    () => routeForm?.route?.params?.itemId,
    [routeForm?.route?.params?.itemId]
  );

  const priority = useMemo(() => {
    if (workflows && workflows[idStringWorkflow]) {
      return workflows[idStringWorkflow]?.length + 1;
    } else {
      return 1;
    }
  }, [workflows, idStringWorkflow]);

  const requiredFields = useMemo(() => {
    return Object.keys(newWorkflow).length
      ? {
          name: newWorkflow?.name,
          channel: getPlatforms(newWorkflow?.conditions).length,
          action: newWorkflow?.actions[0]?.parameters.id
        }
      : {};
  }, [newWorkflow]);

  const [conditionMessage, setConditionMessage] = useState<Condition<boolean>>({
    type: TypeConditions.objectProperty,
    property: PropertyConditions?.isFirstMessage,
    property_type: PropertyType?.string,
    condition: ConditionsWorkflow.eq,
    value: true
  });

  useEffect(() => {
    if (newWorkflow && itemId) {
      //Solo se ejecuta si se esta editando
      const isFirstMessageCondition = newWorkflow?.conditions?.find(
        (condition) => condition?.property === PropertyConditions?.isFirstMessage
      );

      if (isFirstMessageCondition) {
        // Code to handle the condition when isFirstMessage is true
        setConditionMessage(isFirstMessageCondition);
      } else {
        setConditionMessage({
          ...conditionMessage,
          value: false
        });
      }
    }
  }, [newWorkflow, itemId]);

  useEffect(() => {
    if (newWorkflow) {
      const ranges = findLabelRangeDate(newWorkflow);

      if (ranges) {
        ranges?.type === 'AND'
          ? dispatch(setDatesRanges([ranges]))
          : dispatch(setDatesRanges(ranges?.conditions));
      }
    }
  }, [newWorkflow]);

  const onChangeName = (value) => {
    dispatch(
      setCustomPropietyOfWorkflow({
        idString: idStringWorkflow,
        value: value?.nativeEvent?.text,
        propiety: 'name'
      })
    );
  };

  const handleCreateWorkflow = () => {
    setCreating(true);
    let workflowToCreate: Workflow = {} as Workflow;
    let ranges = {} as any;
    //validar que trigger, name, conditions inlcuya un objeto que tenga el property id_channel y que en actions el primer elemento del array tenga el parameters?.id
    if (
      newWorkflow?.trigger &&
      newWorkflow?.name &&
      getPlatforms(newWorkflow?.conditions).length &&
      newWorkflow?.actions[0]?.parameters?.id
    ) {
      if (rangeDate?.length > 1) {
        ranges = {
          type: 'OR',
          label: 'range_date',
          conditions: rangeDate
        };
      } else {
        ranges = { ...rangeDate[0] };
      }

      const conditionsWithoutRangeDate = {
        ...newWorkflow,
        conditions: newWorkflow?.conditions?.filter(
          (condition) =>
            condition?.label !== 'range_date' &&
            condition?.property !== PropertyConditions?.isFirstMessage
        )
      };

      if (conditionMessage.value) {
        workflowToCreate = {
          ...conditionsWithoutRangeDate,
          conditions: [...conditionsWithoutRangeDate.conditions, { ...conditionMessage }]
        };
      } else {
        workflowToCreate = {
          ...conditionsWithoutRangeDate,
          conditions: [...conditionsWithoutRangeDate.conditions]
        };
      }

      if (Object.keys(ranges).length > 0) {
        workflowToCreate = {
          ...workflowToCreate,
          conditions: [...workflowToCreate.conditions, { ...ranges }]
        };
      }

      if (itemId) {
        dispatch(editWorkflow({ id: itemId, workflow: workflowToCreate }))
          .then((res) => {
            setCreating(false);
            dispatch(updateWorkflow({ idString: idStringWorkflow, workflow: res?.data }));
            if (IS_WEB_PC) {
              dispatch(setMenuRigth(false));
            } else {
              routeForm?.navigation.goBack();
            }
            dispatch(setCreatingWorkflow({} as Workflow));
            dispatch(clearAllRangeDate());
            showToast({
              subtitle: translate('automatizations.automatizationUpdated'),
              variant: 'accent',
              type: 'success'
            });
          })
          .catch((error) => {
            setCreating(false);
            console.log('error al editar workflow', error);
            showToast({
              subtitle: 'Error al editar automatización',
              variant: 'accent',
              type: 'error'
            });
          });
      } else {
        workflowToCreate = {
          ...workflowToCreate,
          priority: priority
        };
        dispatch(createWorkflow(workflowToCreate))
          .then((res) => {
            setCreating(false);
            dispatch(setNewWorkflow({ idString: idStringWorkflow, workflow: res?.data }));
            if (IS_WEB_PC) {
              dispatch(setMenuRigth(false));
            } else {
              routeForm?.navigation.goBack();
            }
            dispatch(setCreatingWorkflow({} as Workflow));
            dispatch(clearAllRangeDate());
            showToast({
              subtitle: translate('automatizations.automatizationCreated'),
              variant: 'accent',
              type: 'success'
            });
          })
          .catch((error) => {
            setCreating(false);
            console.log('error al crear workflow', error);
            showToast({
              subtitle: 'Error al crear automatización',
              variant: 'accent',
              type: 'error'
            });
          });
      }
    } else {
      console.log('no se puede crear', newWorkflow);
    }
  };

  return (
    <TVView
      style={{
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
        gap: 8
      }}
    >
      <ThemedScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexGrow: 1,
          display: 'flex',

          gap: 10,
          height: '100%'
        }}
      >
        <SectionFrom
          title="General"
          idStringSubtitle="Define el nombre y descripción de la automatización."
        >
          <TVView style={{ gap: 8 }}>
            <InputTextForm
              value={newWorkflow?.name ?? ''}
              onChange={onChangeName}
              isRequired
              label={translate('name')}
              placeholder={translate('actions.typeAName')}
              errorText="Error"
              bgColor={newWorkflow?.name ? 'backgroundActive' : 'backgroundSecondary'}
            />
            <LayoutForm
              idString="channel"
              icon={true}
              idStringTooltip="automatizations.channelTooltip"
            >
              <MenuCheckbox />
            </LayoutForm>

            <LayoutForm
              idString="trigger"
              icon={true}
              idStringTooltip="automatizations.triggerTooltip"
            >
              <MenuTrigger idString={idStringWorkflow} />
            </LayoutForm>

            <LayoutForm
              idString="automatizations.inconmingMessage"
              icon={true}
              idStringTooltip="automatizations.inconmingMessageTooltip"
            >
              <FirstMessageCheckbox
                setConditionMessage={setConditionMessage}
                conditionMessage={conditionMessage}
              />
            </LayoutForm>

            {routeForm?.route?.name === Routes.AutomatizationTemplate ? (
              <LayoutForm
                idString="templates.title"
                icon={true}
                idStringTooltip="automatizations.templatesTooltip"
                leftIcon={
                  <MainCta
                    icon={IconReloadList}
                    variant="outline"
                    onPress={() =>
                      dispatch(getTemplatesWorkflows({ idStringWorkflow }))
                        .then((res: any) => {
                          console.log('getTemplatesWorkflows', res?.data);
                        })
                        .catch((err) => {
                          console.log('getTemplatesWorkflows', err);
                        })
                    }
                  />
                }
              >
                <MenuTemplate idString={idStringWorkflow} />
              </LayoutForm>
            ) : (
              <LayoutForm idString="bot" icon={true} idStringTooltip="automatizations.botTooltip">
                <MenuBot idString={idStringWorkflow} />
              </LayoutForm>
            )}
          </TVView>
        </SectionFrom>
        <SectionFrom
          title="Workflow"
          idStringSubtitle="Define día, fecha e intervalos del workflow."
        >
          <ItemRangeDate rangeDate={rangeDate} routeForm={routeForm} />
        </SectionFrom>
      </ThemedScrollView>
      <THView
        style={{
          justifyContent: 'flex-end',
          width: '100%'
        }}
      >
        <MainCta
          labelId={itemId ? 'actions.save' : 'actions.add'}
          variant="outline"
          sx={{
            borderWidth: 1,
            borderColor: '$border'
          }}
          disabled={
            creating
              ? creating
              : requiredFields.name && requiredFields.channel && requiredFields.action
                ? false
                : true
          }
          icon={IconSave}
          loading={creating}
          iconPosition="left"
          onPress={() => handleCreateWorkflow()}
        />
      </THView>
    </TVView>
  );
};

export default React.memo(FormAutomatization);
