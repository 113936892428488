import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  setConversations,
  setFilterStateAndUnreadCount,
  setFragments,
  setParticipants,
  setSectionsByConverId
} from '@/redux/actionTypes';
import { setOutConversationToFilterQueue } from '@/redux/slices/conversation';
import {
  getConversationById,
  searchConversationInOmnibox,
  searchMessagesInOmnibox
} from '@/redux/slices/conversation/requests';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { Routes } from '@/routers/routes';
import { Message } from '@/types/message';
import { findConversationFilter, findFragmentByMessageId, findIndexById } from '@/utils/functions';
import { OmniboxContextData, OmniboxProviderInterface, OmniboxpropsInterface } from './interfaces';
import { setTemporallyPreviousMessage } from '@/redux/slices/messages';
import { IS_WEB_PC } from '@/utils/functions';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParams } from '@/routers/interfaces';

// Create the context
const OmniboxContext = createContext<OmniboxContextData | undefined>(undefined);
export const useOmnibox = () => {
  return useContext(OmniboxContext);
};

// ? LEER: Readme para entender el funcionamiento de este componente

// Create the component Provider
function OmniboxProvider(props: OmniboxProviderInterface) {
  const setModalVisible = props?.setModalVisible;

  const sections = useAppSelector((state: RootState) => state.messages.sections);
  const filterConversationsTeam = useAppSelector(
    (state: RootState) => state.conversations.filterConversations
  );
  const dispatch = useAppDispatch();
  const navigation = useNavigation<StackNavigationProp<RootStackParams>>();
  const teamId = useAppSelector((state: RootState) => state.user.team.id);
  const [loadingMore, setLoadingMore] = useState<{ [key: string]: boolean }>({});
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [loadingSelected, setLoadingSelected] = useState<{ [key: string]: boolean }>({});
  const [searchQuery, setSearchQuery] = React.useState('');
  const [error, setError] = React.useState<{ [key: string]: boolean }>({});
  const [noResults, setNoResults] = React.useState<{ [key: string]: boolean }>({});
  const [data, setData] = React.useState<OmniboxpropsInterface>();
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});

  const filterConversations = useMemo(
    () => filterConversationsTeam[teamId] || {},
    [filterConversationsTeam, teamId]
  );

  let debounceTimer: any = null;

  useEffect(() => {
    clearTimeout(debounceTimer);
    handleSearch();
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchQuery]);

  const handleSearch = useCallback(() => {
    if (debounceTimer && searchQuery?.length < 2) {
      clearTimeout(debounceTimer);
    }
    debounceTimer = setTimeout(() => {
      if (searchQuery?.length > 2) {
        setNoResults({ conversations: false, messages: false });
        if (selectedFilters?.length === 0) {
          // NO HAY FILTRO SELECCIONADO BUSCA EN TODOS
          getAllData();
        } else if (selectedFilters?.length === 1 && selectedFilters?.includes('conversations')) {
          setLoading({ conversations: true, messages: false });
          searchConver();
        } else if (selectedFilters?.length === 1 && selectedFilters?.includes('messages')) {
          setLoading({ conversations: false, messages: true });
          searchMessages();
        } else if (
          selectedFilters?.length === 2 &&
          selectedFilters?.includes('conversations') &&
          selectedFilters?.includes('messages')
        ) {
          getAllData();
        }
      } else {
        setData({
          listConversations: {
            listConversations: [],
            totalPages: null,
            currentPage: null,
            nextPage: null
          },
          listMessage: {
            listMessage: [],
            totalPages: null,
            currentPage: null,
            nextPage: null
          }
        });
        setNoResults({ conversations: false, messages: false });
      }
    }, 1000);
  }, [searchQuery, selectedFilters, debounceTimer]);

  const onPressMessage = (message: Message) => {
    setLoadingSelected({ [message?.id_message]: true });
    const result = findConversationFilter(message?.id_conversation, filterConversations);
    console.log('findConversationFilter:', { result });

    if (result) {
      console.info('esta la conver');
      // get index of message in sections[message?.id_conversation]
      const index = findIndexById(sections[message?.id_conversation], message?.id_message);
      // si el mensaje no existe hay que buscarlo en la base de datos
      if (index === -1) {
        console.info('esta la conver pero no el mensaje index -1');
        hanldeGetConverAndMessages({
          id_message: message?.id_message,
          id_conversation: message?.id_conversation,
          filter: 'searchs',
          teamId
        });

        setLoadingSelected({ [message?.id_message]: false });
      } else {
        console.info('esta la conver y el mensaje en state');
        navigateToConversation({
          idConver: message?.id_conversation,
          idMessage: message?.id_message,
          platform: result.mainKey === 'clients' ? 'external' : 'internal',
          filter: result.filterKey
        });
        setTimeout(() => {
          setLoadingSelected({ [message?.id_message]: false });
          setModalVisible(false);
        }, 2000);
      }
    } else {
      console.info('no esta la conver ni le mensaje');
      hanldeGetConverAndMessages({
        id_message: message?.id_message,
        id_conversation: message?.id_conversation,
        teamId
      });
      setLoadingSelected({ [message?.id_message]: false });
    }
  };

  const hanldeGetConverAndMessages = ({
    id_message,
    id_conversation,
    filter,
    teamId
  }: {
    id_message?: string;
    id_conversation: string;
    filter?: string;
    teamId?: string;
  }) => {
    console.log({ teamId });

    getConverAndMessages({
      id_message: id_message,
      id_conversation: id_conversation,
      filter: filter ?? 'searchs'
    }).then(
      ({
        platform,
        count,
        previousMessages,
        currentFragment,
        conversation,
        filter,
        participant,
        fragments
      }) => {
        Promise.all([
          dispatch(setTemporallyPreviousMessage({ [id_conversation]: previousMessages })),
          dispatch(
            setConversations({
              conversations: conversation
            })
          ),
          dispatch(
            setFilterStateAndUnreadCount({
              filter: filter,
              type: platform,
              count,
              teamId
            })
          ),
          dispatch(setParticipants(participant)),
          dispatch(setFragments(fragments)),
          dispatch(setSectionsByConverId({ id: id_conversation, sections: currentFragment }))
        ]).finally(() => {
          setTimeout(() => {
            dispatch(
              setOutConversationToFilterQueue({
                conversationId: id_conversation,
                filter: 'searchs',
                teamId
              })
            );
            navigateToConversation({
              idConver: id_conversation,
              idMessage: id_message,
              platform: platform,
              filter: 'searchs'
            });
            setModalVisible(false);
          }, 2000);
        });
      }
    );
  };

  const onPressConver = (idCOnversation: string) => {
    setLoadingSelected({ [idCOnversation]: true });
    const result = findConversationFilter(idCOnversation, filterConversations[teamId]);
    console.log({ result, filterConversations });

    if (result) {
      navigateToConversation({
        idConver: idCOnversation,
        idMessage: null,
        platform: result.mainKey,
        filter: result.filterKey
      });
      setTimeout(() => {
        setModalVisible(false);
        setLoadingSelected({ [idCOnversation]: false });
      }, 2000);
    } else {
      hanldeGetConverAndMessages({ id_conversation: idCOnversation, teamId });
    }
  };

  const loadMoreConversation = (page) => {
    setLoadingMore({ conversations: true });
    dispatch(searchConversationInOmnibox({ text: searchQuery, page })).then((res) => {
      setData({
        listConversations: {
          listConversations: [
            ...data?.listConversations?.listConversations,
            ...res?.listConversations?.listConversations
          ],
          totalPages: data?.listConversations?.totalPages,
          currentPage: data?.listConversations?.currentPage,
          nextPage: res?.listConversations?.nextPage
        },
        listMessage: data.listMessage
      });
      setLoadingMore({ conversations: false });
    });
  };

  function loadMoreMessages(page) {
    setLoadingMore({ messages: true });
    dispatch(searchMessagesInOmnibox({ text: searchQuery, page })).then((res) => {
      setData({
        listConversations: data?.listConversations,
        listMessage: {
          listMessage: [
            ...data?.listMessage?.listMessage,
            ...res?.searchMessageResult?.listMessage
          ],
          totalPages: data?.listMessage?.totalPages,
          currentPage: data?.listMessage?.currentPage,
          nextPage: res?.searchMessageResult?.nextPage
        }
      });
      setLoadingMore({ messages: false });
    });
  }

  const getAllData = () => {
    setLoading({
      conversations: true,
      messages: true
    });

    Promise.all([searchConver(), searchMessages()]);
  };

  const searchMessages = () => {
    dispatch(searchMessagesInOmnibox({ text: searchQuery, page: 1 }))
      .then((res) => {
        if (res?.searchMessageResult?.listMessage?.length > 0) {
          setData((prev) => {
            return {
              ...prev,
              listMessage: {
                listMessage:
                  res?.searchMessageResult?.listMessage?.length > 0
                    ? [...res?.searchMessageResult?.listMessage]
                    : [...prev?.listMessage?.listMessage],
                totalPages: data?.listMessage?.totalPages,
                currentPage: data?.listMessage?.currentPage,
                nextPage: res?.searchMessageResult?.nextPage
              }
            };
          });
        } else {
          console.log('no hay resultados searchMessages');
          setData((prev) => {
            return {
              ...prev,
              listMessage: {
                listMessage: [],
                totalPages: 0,
                currentPage: 0,
                nextPage: 0
              }
            };
          });
          setNoResults((prev) => {
            return {
              ...prev,
              messages: true
            };
          });
        }
        setLoading((prev) => {
          return {
            ...prev,
            messages: false
          };
        });
      })
      .catch((err) => {
        console.error('err', err);
        if (err?.code !== 'ERR_CANCELED') {
          setError((prev) => {
            return {
              ...prev,
              messages: true
            };
          });
          setLoading((prev) => {
            return {
              ...prev,
              messages: false
            };
          });
        }
      });
  };

  const searchConver = () => {
    dispatch(searchConversationInOmnibox({ text: searchQuery, page: 1 }))
      .then((res) => {
        console.log('searchConversationInOmnibox', { searchQuery, res });
        if (res?.listConversations?.listConversations?.length > 0) {
          setData((prev) => {
            return {
              ...prev,
              listConversations: {
                listConversations: [...res?.listConversations?.listConversations],
                totalPages: prev?.listConversations?.totalPages,
                currentPage: prev?.listConversations?.currentPage,
                nextPage: res?.listConversations?.nextPage
              }
            };
          });
        } else {
          console.log('no hay resultados searchConver', { searchQuery });
          setData((prev) => {
            return {
              ...prev,
              listConversations: {
                listConversations: [],
                totalPages: 0,
                currentPage: 0,
                nextPage: 0
              }
            };
          });
          setNoResults((prev) => {
            return {
              ...prev,
              conversations: true
            };
          });
        }
        setLoading((prev) => {
          return {
            ...prev,
            conversations: false
          };
        });
      })
      .catch((err) => {
        console.error('err', err);
        if (err?.code !== 'ERR_CANCELED') {
          setError((prev) => {
            return {
              ...prev,
              conversations: true
            };
          });
          setLoading((prev) => {
            return {
              ...prev,
              conversations: false
            };
          });
        }
      });
  };

  const navigateToConversation = ({ idConver, idMessage, platform, filter }) => {
    IS_WEB_PC
      ? navigation.reset({
          index: 0,
          routes: [
            {
              name: filter,
              params: { filter, id: idConver },
              state: {
                index: 0,
                routes: [
                  {
                    name: Routes.Conversation,
                    params: { id: idConver, index: idMessage }
                  }
                ]
              }
            }
          ]
        })
      : navigation.navigate(Routes.ConversationStack, {
          id: idConver,
          filter,
          index: idMessage
        });
  };

  const getConverAndMessages = async ({
    id_message,
    id_conversation,
    filter
  }: {
    id_message?: string;
    id_conversation: string;
    filter?: string;
  }) => {
    let platform = '';
    return new Promise((resolve, reject) => {
      dispatch(getConversationById({ conversationId: id_conversation, idMessage: id_message }))
        .then((res) => {
          platform =
            Object.values<any>(res?.data?.conversation)[0]?.platform === 'internal'
              ? Routes.Chat
              : Routes.clientes;

          const count = {
            [filter ?? res?.data?.filter]: {
              conversations: [
                {
                  id: id_conversation,
                  messageCount: res?.data?.section[id_conversation]?.length,
                  unreadMessageCount: 0,
                  updatedAt: Object.values<any>(res?.data?.conversation)[0]?.updatedAt
                }
              ],
              unread: { [id_conversation]: 0 },
              conversationCountWithOpenFragment: 0
            }
          };

          const [previousMessages, currentFragment] = findFragmentByMessageId({
            array: res?.data?.section[id_conversation],
            id: id_message
          });

          resolve({
            platform,
            count,
            previousMessages,
            currentFragment,
            conversation: res?.data?.conversation,
            filter: filter ?? res?.data?.filter,
            participant: id_message ? res?.data?.participant : res?.data?.participantes,
            fragments: id_message ? res?.data?.fragments : res?.data?.lastFragmentInfo
          });
        })
        .catch((err) => {
          console.log('err', err);
          reject(err); // Rechazar la promesa si hay un error
        });
    });
  };

  return (
    <OmniboxContext.Provider
      value={{
        onPressMessage,
        loadingSelected,
        loading,
        loadingMore,
        error,
        setSelectedFilters,
        setSearchQuery,
        data,
        searchQuery,
        loadMoreConversation,
        loadMoreMessages,
        selectedFilters,
        onPressConver,
        noResults
      }}
    >
      {props.children}
    </OmniboxContext.Provider>
  );
}

export default React.memo(OmniboxProvider);
