import React from 'react';
import { StyleSheet, TextStyle } from 'react-native';

import { TText } from '@/components/themed';
import { colorsProps } from '@/hooks/useTheme';

type TextFormatterProps = {
  children: string;
  numberOfLines?: number;
  bold?: boolean;
  color?: colorsProps;
};

const TextFormatter: React.FC<TextFormatterProps> = ({
  children,
  numberOfLines = 0,
  color,
  bold
}) => {
  const styls = StyleSheet.create({
    bold: { fontWeight: 'bold' },
    italic: { fontStyle: 'italic' },
    strikethrough: { textDecorationLine: 'line-through' },
    default: {} // Estilo por defecto
  });

  const parseAndRenderText = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9.-]+)/g;

    const emailMatches: string[] = [];
    const urlMatches: string[] = [];

    // Reemplazar correos y URLs por marcadores temporales
    text = text.replace(urlRegex, (match) => {
      urlMatches.push(match);
      return `[[URL${urlMatches.length - 1}]]`; // Marcador URL
    });

    text = text.replace(emailRegex, (match) => {
      emailMatches.push(match);
      return `[[EMAIL${emailMatches.length - 1}]]`; // Marcador EMAIL
    });

    // Dividir el texto usando los marcadores y respetar saltos de línea
    const parts = text.split(/(\[\[URL\d+\]\]|\[\[EMAIL\d+\]\]|\n)/g);

    return parts
      .map((part, index) => {
        // Manejar saltos de línea
        if (part === '\n') {
          return (
            <TText key={index} style={{ lineHeight: 1 }}>
              {'\n'}
            </TText>
          ); // Salto de línea
        }

        // Verificar si es un marcador de URL o correo
        if (part.startsWith('[[URL') && part.endsWith(']]')) {
          const urlIndex = parseInt(part.match(/\d+/)[0], 10); // Extraer índice
          return (
            <TText
              lineBreakMode="clip"
              ellipsizeMode="head"
              numberOfLines={numberOfLines}
              key={index}
              style={styls.default} // Sin estilos
              color={color ?? 'text'}
            >
              {urlMatches[urlIndex]}
            </TText>
          );
        }

        if (part.startsWith('[[EMAIL') && part.endsWith(']]')) {
          const emailIndex = parseInt(part.match(/\d+/)[0], 10); // Extraer índice
          return (
            <TText
              lineBreakMode="clip"
              ellipsizeMode="head"
              numberOfLines={numberOfLines}
              key={index}
              style={styls.default} // Sin estilos
              color={color ?? 'text'}
            >
              {emailMatches[emailIndex]}
            </TText>
          );
        }

        // Para partes de texto normal, aplicar estilos
        const formattedParts = part
          .split(/(\*[^*]*\*|_[^_]*_|~[^~]*~)/g)
          .map((subPart, subIndex) => {
            let textStyle: TextStyle = styls.default;

            // Determinar el estilo y eliminar los caracteres de formato
            if (subPart.startsWith('*') && subPart.endsWith('*')) {
              textStyle = styls.bold;
              subPart = subPart.slice(1, -1); // Eliminar asteriscos
            } else if (subPart.startsWith('_') && subPart.endsWith('_')) {
              textStyle = styls.italic;
              subPart = subPart.slice(1, -1); // Eliminar guiones bajos
            } else if (subPart.startsWith('~') && subPart.endsWith('~')) {
              textStyle = styls.strikethrough;
              subPart = subPart.slice(1, -1); // Eliminar tildes
            }

            // Devolver el componente de texto con estilo
            return (
              <TText
                lineBreakMode="clip"
                ellipsizeMode="head"
                numberOfLines={numberOfLines}
                key={`${index}-${subIndex}`} // Asegurar clave única
                style={textStyle}
                color={color ?? 'text'}
              >
                {subPart.trim()}
              </TText>
            );
          });

        return formattedParts; // Devolver todas las partes formateadas
      })
      .filter((part) => part); // Filtrar partes vacías
  };

  return (
    <TText
      lineBreakMode="clip"
      ellipsizeMode="head"
      numberOfLines={numberOfLines}
      selectable
      color={color ?? 'text'}
      style={{
        fontWeight: bold ? 'bold' : 'normal'
      }}
    >
      {typeof children === 'string' ? parseAndRenderText(children) : children}
    </TText>
  );
};

export default React.memo(TextFormatter, (prevProps, nextProps) => {
  return prevProps.children === nextProps.children;
});
