import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as IntentLauncher from 'expo-intent-launcher';
import * as Sharing from 'expo-sharing';

export const LIMIT_OF_CHARACTERS = 5000;

export const URL_BASE_PMS = 'https://pms.pxsol.com';
export const URL_EDIT_CONTACT = `${URL_BASE_PMS}/apps/neo_contactos/main.html?appID=62`;
export const URL_PMS_EDIT_CONTACT = `${URL_BASE_PMS}/apps/neo_contactos/main.html?secondaryWindow=/apps/neo_contactos/edit_contact.php?id=`;
export const URL_PMS_NEW_CONTACT = `${URL_BASE_PMS}/apps/neo_contactos/main.html?secondaryWindow=/apps/neo_contactos/includes/new_contact.php`;
export const URL_NEW_CONTACT = `${URL_BASE_PMS}/apps/neo_contactos/main.html?appID=62`;
export const URL_INFO_CONTACT = `${URL_BASE_PMS}/apps/neo_contactos/main.html?secondaryWindow=/apps/neo_contactos/info_contact.php?id=`;
export const CREATE_TEMPLATE_PMS = `${URL_BASE_PMS}/apps/neo_templates/main.html`;
export const CREATE_UER = `${URL_BASE_PMS}/apps/home/main.html?primaryWindow=/apps/users/list.php&secondaryWindow=/apps/users/new_user.php`;
export const INPUT_HEIGHT = 30;
export const SUPORT_PHONE = '+5492616703818';
export const URL_TO_CREATE_WIDGET = `${URL_BASE_PMS}/apps/widget_web/list.html?primaryWindow=/apps/widget_web/wizard.php`;

export const URL_TO_CONNECT_CHANNEL = 'https://share.hsforms.com/19QZIMP4VRjiw_7FeK3pAiA4ekty';

export const MAX_HEIGHT_INPUT = 560;
export const MIN_INPUT_HEIGHT = 40;
export const OPERACIONES = 1848;

export const EMOJI_REPLACEMENT = {
  '😭': ['ToT', 'T-T', 'T_T', 'T.T', ':((', ':-(('],
  '😓': ["'-_-"],
  '😜': [';p', ';-p', ';P', ';-P'],
  '😑': ['-_-'],
  '😢': [":'(", ":'-("],
  '😞': [':(', ':-(', '=(', ')=', ':['],
  '😐': [':|', ':-|'],
  '😛': [':P', ':-P', ':p', ':-p', '=P', '=p'],
  '😁': [':D', ':-D', '=D', ':d', ':-d', '=d'],
  '😗': [':*', ':-*'],
  '😇': ['O:)', 'O:-)'],
  '😳': ['O_O', 'o_o', '0_0'],
  '😊': ['^_^', '^~^', '=)'],
  '😠': ['>:(', '>:-(', '>:o', '>:-o', '>:O', '>:-O'],
  '😎': ['8)', 'B)', '8-)', 'B-)', ':))'],
  '😚': ['-3-'],
  '😉': [';)', ';-)'],
  '😲': [':O', ':o', ':-O', ':-o'],
  '😣': ['>_<', '>.<'],
  '😘': [';*', ';-*'],
  '😕': [':/', ':-/', ':\\', ':-\\', '=/', '=\\'],
  '🙂': [':)', ':]', ':-)', '(:', '(='],
  '♥': ['<3'],
  '😂': [":')"],
  '🤑': ['$-)']
};

export const EMOJI_REGEX = /^\p{Extended_Pictographic}$/u;

function getLuminance(hexColor: string) {
  const rgb = hexColor
    .substring(1)
    .match(/.{2}/g)
    .map((c) => parseInt(c, 16));
  const [r, g, b] = rgb;
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export const colorRandom = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }

  const minLuminance = 100; // Ajuste este valor para definir el límite inferior de luminancia
  const maxLuminance = 200; // Ajuste este valor para definir el límite superior de luminancia
  const luminance = getLuminance(colour);

  if (luminance < minLuminance || luminance > maxLuminance) {
    return colorRandom(`${str}a`); // Agregar un caracter adicional para generar un color diferente
  }

  return colour;
};

export const isImageURL = (url) => /\.(jpg|jpeg|png|gif)$/i.test(url);
export const isDocumentURL = (url) => /\.(pdf|doc|docx)$/i.test(url);

export const checkFileExistence = async (url) => {
  const fileUri = FileSystem.documentDirectory + getUrlFilename(url);
  const fileInfo = await FileSystem.getInfoAsync(fileUri);
  return fileInfo.exists;
};

export const openFile = async (url) => {
  const fileUri = FileSystem.documentDirectory + getUrlFilename(url);
  // Abre el archivo descargado
  if (Platform.OS === 'android') {
    FileSystem.getContentUriAsync(fileUri).then((contentUri) => {
      IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
        data: contentUri,
        flags: 1 << 0, // FLAG_GRANT_READ_URI_PERMISSION
        type: 'application/pdf' // Cambia el tipo según el tipo de archivo
      });
    });
  } else {
    // En iOS, simplemente usa el URI de archivo
    IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
      data: fileUri,
      type: 'application/pdf' // Cambia el tipo según el tipo de archivo
    });
  }
};

export const getUrlFilename = (url) => {
  const name = url.split('/').pop();
  return name.split('.').pop();
};
export const getFileExtension = (url) => {
  return url.split('.').pop();
};
export const downloadImage = async (url) => {
  console.log('url', url);

  const fileName = url.split('/').pop().split('.')[0]; // Obtiene el nombre del archivo sin la extensión
  const fileUri = FileSystem.documentDirectory + fileName;

  try {
    const urlImg = await FileSystem.downloadAsync(url, fileUri);
    return urlImg;
  } catch (error) {
    console.error('Error al descargar la imagen', error);
    return error;
  }
};

export const shareFile = async (url) => {
  // Reemplaza 'downloaded-document.pdf' con el nombre del archivo que deseas compartir
  const fileUri = `${FileSystem.documentDirectory + getUrlFilename(url)}.${getFileExtension(url)}`;

  try {
    await Sharing.shareAsync(fileUri);
  } catch (error) {
    return error;
  }
};

// Fragmetns status
export const FRAGMENT_OPEN = 'open';
export const FRAGMENT_CLOSED = 'closed';
export const FRAGMENT_EXPIRED = 'expired';
export const FRAGMENT_NEW = 'new';
export const FRAGMENT_FAILED = 'failed';
export const FRAGMENT_PENDING = 'pending';

export const CLOSED_COMPONENT = [FRAGMENT_CLOSED, FRAGMENT_EXPIRED, FRAGMENT_NEW, FRAGMENT_FAILED];
export const NO_RESPONSE = [FRAGMENT_PENDING];

export const CHIP_DISABLED = [FRAGMENT_CLOSED, FRAGMENT_EXPIRED, FRAGMENT_FAILED, FRAGMENT_NEW];

export const MESSAGE_CLOSED = [FRAGMENT_CLOSED, FRAGMENT_EXPIRED];

export const READ_MESSAGES_IS_PXSOL = ['1050', '1848'];

export const closedMessages = {
  closed: {
    idStringTitle: 'messages.fragmentClosedManually',
    idStringSubtitle: 'messages.fragmentClosedManuallySubtitle'
  },
  expired: {
    idStringTitle: 'messages.fragmetExpired',
    idStringSubtitle: 'messages.fragmetExpiredSubtitle'
  },
  new: {
    idStringTitle: 'messages.fragmetNew',
    idStringSubtitle: 'messages.fragmetNewSubtitle'
  },
  failed: {
    idStringTitle: 'messages.fragmentFailed',
    idStringSubtitle: 'messages.fragmentFailedSubtitle'
  },
  pending: {
    idStringTitle: 'messages.fragmentPending',
    idStringSubtitle: 'messages.fragmentPendingSubtitle'
  },
  'closed-widget': {
    idStringTitle: 'messages.closeWidget',
    idStringSubtitle: 'messages.closeWidgetSubtitle'
  },
  'expired-widget': {
    idStringTitle: 'messages.closeWidget',
    idStringSubtitle: 'messages.closeWidgetSubtitle'
  },
  'channel-disabled': {
    idStringTitle: 'messages.channelDisabled',
    idStringSubtitle: 'messages.channelDisabledDescription'
  }
};

export const SortOrder = {
  DEFAULT: 'default',
  ASC: 'asc',
  DESC: 'desc'
};

export const noAddUrl = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/gif',
  'video/mp4',
  'video/quicktime'
];
export const KEY_OMIT_HIGILIGHT = ['ArrowDown', 'ArrowUp', 'Enter', ''];

export const arrayDays = [
  { title: 'Lunes', value: 1, label: 'L' },
  { title: 'Martes', value: 2, label: 'M' },
  { title: 'Miércoles', value: 3, label: 'M' },
  { title: 'Jueves', value: 4, label: 'J' },
  { title: 'Viernes', value: 5, label: 'V' },
  { title: 'Sábado', value: 6, label: 'S' },
  { title: 'Domingo', value: 7, label: 'D' }
];
