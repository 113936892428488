import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  clearParticipantsByConversationId,
  setNewsParticipants,
  setParticipants,
  updateParticipant
} from '@/redux/actionTypes';
import {
  ParticipantsConverastionsIndexed,
  ParticipantsState,
  SetNewParticipant
} from './interfaces';
import { IParticipantsNewConverExternal } from '../conversation/interfaces';

const initialState: ParticipantsState = {
  participants: {},
  participant: {} as IParticipantsNewConverExternal
};

const participantsSlice = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    clearParticipants: (state) => {
      state.participants = {};
    },
    setParticipant: (state, action: PayloadAction<IParticipantsNewConverExternal>) => {
      state.participant = {
        ...state.participant,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      setParticipants,
      (state, action: PayloadAction<ParticipantsConverastionsIndexed>) => {
        state.participants = {
          ...state.participants,
          ...action.payload
        };
      }
    );

    builder.addCase(setNewsParticipants, (state, action: PayloadAction<SetNewParticipant>) => {
      state.participants = {
        ...state.participants,
        [action.payload.conversation_id]: action.payload.participants
      };
    });

    builder.addCase(clearParticipantsByConversationId, (state, action) => {
      delete state.participants[action.payload];
    });

    builder.addCase(updateParticipant, (state, action) => {
      const idConversation = Object.keys(action.payload)[0];
      state.participants[idConversation] = action.payload[idConversation];
    });
  }
});

export const { clearParticipants, setParticipant } = participantsSlice.actions;

export default participantsSlice.reducer;
