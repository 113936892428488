import { ActivityIndicator } from 'react-native';
import React, { useMemo } from 'react';
import { createSpinner } from '@gluestack-ui/spinner';
import { colorsProps, useThemeColor } from '@/hooks/useTheme';

const UISpinner = createSpinner({ Root: ActivityIndicator });

type ISpinnerProps = React.ComponentProps<typeof UISpinner> & {
  lightColor?: string;
  darkColor?: string;
  color?: colorsProps;
};

const Spinner = React.forwardRef<React.ElementRef<typeof UISpinner>, ISpinnerProps>(
  ({ style, lightColor, darkColor, color, ...props }, ref) => {
    const spinnerColor = useThemeColor({ light: lightColor, dark: darkColor }, color);

    const memoizedStyle = useMemo(() => [style], [style]);

    return <UISpinner style={memoizedStyle} ref={ref} {...props} color={spinnerColor} />;
  }
);

Spinner.displayName = 'Spinner';

export { Spinner };
