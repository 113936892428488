import React, { memo } from 'react';
import { THView, TVView } from '../themed';
import { StyleSheet } from 'react-native';
import CustomSkeleton from './CustomSckeleton';

const SkeletonContactCard = () => {
  return (
    <THView style={style.container}>
      <CustomSkeleton type="circle" size={55} background="backgroundActive" />
      <TVView
        style={{
          width: '80%',
          gap: 0
        }}
      >
        <CustomSkeleton type="rectangle" background="backgroundActive" height={20} width="100%" />
        <CustomSkeleton type="rectangle" background="backgroundActive" height={5} width="100%" />
        <CustomSkeleton type="rectangle" background="backgroundActive" height={5} width="100%" />
      </TVView>
    </THView>
  );
};

const style = StyleSheet.create({
  container: {
    height: 80,
    width: '100%',
    gap: 8,
    padding: 8
  }
});
export default memo(SkeletonContactCard);
