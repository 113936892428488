import { combineReducers } from 'redux';
import UserReducer from '../slices/auth';
import themeReducer from '../slices/theme';
import ConversationReducer from '../slices/conversation';
import contactosReducer from '../slices/contacts';
import messagesReducer from '../slices/messages';
import templatesReducer from '../slices/templates';
import filtersReducer from '../slices/filters';
import fragmentsReducer from '../slices/fragments';
import participantsReducer from '../slices/participants';
import labelsReducer from '../slices/labels';
import automatizationReducer from '../slices/automatization';
import apolloReducers from '../slices/apollo';
import ticketReducer from '../slices/tickets';

export default combineReducers({
  templates: templatesReducer,
  filters: filtersReducer,
  user: UserReducer,
  settings: themeReducer,
  conversations: ConversationReducer,
  contacts: contactosReducer,
  messages: messagesReducer,
  fragments: fragmentsReducer,
  participants: participantsReducer,
  labels: labelsReducer,
  automatization: automatizationReducer,
  apollo: apolloReducers,
  ticket: ticketReducer
});
