import React, { memo } from 'react';
import AcordionCustom from '@/atoms/AcordionCustom';
import { InfoOutline, MessageBadge } from '@/assets/icons/Icons';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import translate from '@/helpers/traslations';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { RootState, useAppSelector } from '@/redux/store';
import ItemChannel from './ItemChannel';

const ChannelsNotifications = () => {
  const companyId = useAppSelector((state: RootState) => state.user?.company?.IDEmpresas);
  const activeChannels = useAppSelector(
    (state: RootState) => state.user?.activeChannels?.[companyId]
  );

  return (
    <AcordionCustom
      iconLeft={MessageBadge}
      idString="notification.channels"
      titleSize="lg"
      bgColor="$background2"
      mbItem="$0"
      defaultOpen={false}
      sx={{
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginBottom: 20,
        borderRadius: 8
      }}
      middleElement={
        <CustomTooltip title={translate('notification.channelsTooltip')}>
          <MaterialCommunity icon={InfoOutline} size="md" color="$info" />
        </CustomTooltip>
      }
    >
      {activeChannels?.map((channel) => <ItemChannel key={channel?.id} channel={channel} />)}
    </AcordionCustom>
  );
};

export default memo(ChannelsNotifications);
