import React, { useEffect, useMemo, useState } from 'react';
import { Linking, StyleSheet } from 'react-native';

import { Image } from 'expo-image';
import { ALL, TODOS } from '@/utils/filters';
import { CogOutline, IconArrowRight, MessageBadge, RoomService } from '@/assets/icons/Icons';
import ErrorBoundary from '../../errorStates/ErrorBoundary';
import ContainerItemSidebarApp from './ContainerItemSidebarApp';
import ItemDrawerSettings from '../../../atoms/ItemDrawerSettings';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store';
import { Routes } from '@/routers/routes';
import MainCta from '@/atoms/MainCta';
import DialogComponent from '@/components/DialogComponent';
import { setDialogConnect, setLimitReached } from '@/redux/slices/theme';
import { URL_TO_CONNECT_CHANNEL } from '@/utils/constants';
import { TView, TVView, TPressable, TText } from '@/components/themed';
import { setIdConversation } from '@/redux/actionTypes';
import { version } from '../../../../package.json';

function SidebarApp(props: { navigation?: any; state?: any }) {
  const dispatch = useAppDispatch();
  const channels = useAppSelector((state: RootState) => state.user.channels);
  const userLoading = useAppSelector((state: RootState) => state.user.userLoading);
  const dialogConnect = useAppSelector((state: RootState) => state.settings.dialogConnect);
  const limitReached = useAppSelector((state: RootState) => state.settings.limitReached);

  const { state } = props;

  const activeRoute = useMemo(() => state?.routes[state?.index], [state]);
  const [avaliableChannel, setAvaliableChannel] = useState<boolean>(true);
  // const [buttonSidebar, setButtonSidebar] = useState([]);

  useEffect(() => {
    if (channels) {
      const findActiveChannel = channels.find((channel) => channel.available);
      setAvaliableChannel(findActiveChannel ? true : false);
    }
  }, [userLoading]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: 95,
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingHorizontal: 4,
          paddingVertical: 16,
          flex: 1
        },
        children: {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          gap: 5
        },
        pressableStyle: {
          width: '100%',
          padding: 10,
          borderRadius: 10
        },
        pressableLogo: {
          width: '100%',
          paddingTop: 12,
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 10
        }
      }),
    []
  );

  const hideDialog = () => setDialogVisible(false);

  const hanldePressClients = () => {
    // se borra el id de la conversacion para que no se abra la conversacion al entrar a clientes
    dispatch(setIdConversation(' '));
    props.navigation.reset({
      index: 0,
      routes: [
        {
          name: Routes.clientes,
          state: {
            index: 0,
            routes: [
              {
                name: Routes.Filter,
                params: { filter: ALL }
              }
            ]
          }
        }
      ]
    });
  };

  const handlePressChat = () => {
    // se borra el id de la conversacion de los parametros de la ruta, para que no se abra la conversacion al entrar a la pantalla de chat
    dispatch(setIdConversation(' '));
    props.navigation.reset({
      index: 0,
      routes: [
        {
          name: Routes.Chat,
          state: {
            index: 0,
            routes: [
              {
                name: Routes.Filter,
                params: { filter: TODOS }
              }
            ]
          }
        }
      ]
    });
  };

  const onPressLogo = () => {
    dispatch(setIdConversation(' '));
    //TODO descomentar despues que se haga la pantalla de bienvenida
    // props.navigation.reset({
    //   index: 0,
    //   routes: [
    //     {
    //       name: RouteWelcome.welcome,
    //       state: {}
    //     }
    //   ]
    // });
  };

  const buttonSidebar = useMemo(() => {
    return [
      {
        id: 1,
        icon: RoomService,
        onPress: () => hanldePressClients(),
        text: 'navigation.clients',
        translate: true,
        avaliable: avaliableChannel,
        routeName: Routes.clientes,
        avaliableChannel
      },
      {
        id: 2,
        icon: MessageBadge,
        onPress: () => handlePressChat(),
        text: 'Hotel',
        translate: false,
        avaliable: true,
        routeName: Routes.Chat
      }
    ];
  }, [avaliableChannel]);
  console.log({ avaliableChannel, channels });

  const setDialogVisible = (value) => {
    dispatch(setDialogConnect(value));
    dispatch(setLimitReached(false));
  };

  return (
    <TVView bgColor="backgroundNavigationPxsol" aria-label="sidebarApp" style={styles.container}>
      <DialogComponent
        dialogVisible={dialogConnect}
        setDialogVisible={setDialogVisible}
        idStringTitle={'messages.upsUpdate'}
        idStringDescription={
          limitReached ? 'messages.freeLimiRreached' : 'messages.upsUpdateDescription'
        }
        inverted
        children={
          <Image
            source={require('../../../assets/images/upgrade.svg')}
            style={{ width: 300, height: 300, marginBottom: 10 }}
          />
        }
        Cta={
          <MainCta
            icon={IconArrowRight}
            iconPosition="left"
            labelId={'actions.upgradePlan'}
            onPress={() => {
              Linking.openURL(URL_TO_CONNECT_CHANNEL);
              hideDialog();
              if (typeof window.gtag === 'function') {
                window.gtag('event', 'upgradePlan', {
                  event_category: 'upgradePlan',
                  event_label: 'upgradePlan'
                });
              }
            }}
          />
        }
      />

      <TView style={styles.children}>
        <TPressable onPress={onPressLogo} style={styles.pressableLogo}>
          <Image
            alt="logo pxsol"
            source={require('../../../assets/px_iso.png')}
            style={{ width: 34, height: 32, marginBottom: 10 }}
          />
        </TPressable>

        {buttonSidebar.map((item, index) => (
          <ErrorBoundary key={index + item.id}>
            <ContainerItemSidebarApp
              avaliableChannel={item.avaliable}
              hanldePressClients={item.onPress}
              activeRoute={activeRoute?.name}
              translate={item.translate}
              routeName={item.routeName}
              icon={item.icon}
              idString={item.text}
            />
          </ErrorBoundary>
        ))}
      </TView>
      <ErrorBoundary>
        <ItemDrawerSettings
          color="blanco"
          icon={CogOutline}
          style={{ routeName: Routes.Settings, activeRoute: activeRoute?.name }}
          onPress={() => props.navigation.navigate(Routes.Settings)}
          idString="navigation.settings"
          direction="column"
          absolute={true}
          paddingRight={0}
          w="lg"
        />
      </ErrorBoundary>
      <TText selectable={false} size="sm" color="secondary">
        v {version}
      </TText>
    </TVView>
  );
}

export default React.memo(SidebarApp, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
