import React, { memo } from 'react';
import AcordionCustom from '@/atoms/AcordionCustom';
import { THView, TText, TVView } from '@/components/themed';
import i18n from '@/providers/Localization';
import { Channel } from '@/screens/interfaces';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { Spinner } from '@gluestack-ui/themed';
import { Switch } from '@gluestack-ui/themed';
import { useLinkss } from '@/providers/LinkProvider';
import { activateApolloAI, listChannelsCompany } from '@/redux/slices/conversation/requests';
import MainCta from '@/atoms/MainCta';
import { Check, IconClose } from '@/assets/icons/Icons';
import OnlyPxsol from '@/atoms/OnlyPxsol';
import { isArray } from '@/utils/functions';

const ChannelsAutomatizations = ({ companyId }: { companyId: string }) => {
  const dispatch = useAppDispatch();
  const activeChannels = useAppSelector(
    (state: RootState) => state.user?.activeChannels?.[companyId]
  );
  const [loading, setLoading] = React.useState<{ [key: string]: boolean }>({});

  const { handleCopyCode, showToast } = useLinkss();

  const onSwitchChange = (value: boolean, channel: Channel) => {
    setLoading({ ...loading, [channel?.id]: true });
    dispatch(
      activateApolloAI({
        channel_id: channel?.id,
        status: value
      })
    )
      .then((res: any) => {
        dispatch(listChannelsCompany({ companyId }))
          .then((res: any) => {
            console.log('listChannelsCompany', res.data);
          })
          .then(() => {
            showToast({
              type: 'success',
              title: 'Cambio realizado',
              subtitle: 'Se ha realizado el cambio correctamente'
            });
            setLoading({ ...loading, [channel?.id]: false });
          });
      })
      .catch((err) => {
        setLoading({ ...loading, [channel?.id]: false });
        if (isArray(err?.message)) {
          err?.message?.forEach((error: string) => {
            showToast({
              type: 'error',
              title: `ERROR: ${err?.transactionId}`,
              subtitle: error,
              cta: err?.transactionId && (
                <MainCta
                  labelId={'actions.copy'}
                  uppercase
                  variant="outline"
                  onPress={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    handleCopyCode({ text: err?.transactionId });
                  }}
                />
              )
            });
          });
          return;
        }
        showToast({
          type: 'error',
          title: 'Error',
          subtitle: 'Ha ocurrido un error al realizar el cambio',
          cta: err?.transactionId && (
            <MainCta
              labelId={'actions.copy'}
              uppercase
              variant="outline"
              onPress={(e: { preventDefault: () => void }) => {
                e.preventDefault();
                handleCopyCode({ text: err?.transactionId });
              }}
            />
          )
        });
      });
  };

  return (
    <AcordionCustom
      idString="chatbot"
      titleSize="lg"
      bgColor="$background"
      mbItem="$0"
      defaultOpen={false}
      sx={{
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginBottom: 20,
        borderRadius: 8
      }}
      middleElement={<OnlyPxsol />}
    >
      <TVView
        bgColor="backgroundSecondary"
        style={{
          width: '100%',
          gap: 8,
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          paddingHorizontal: 15,
          paddingVertical: 12,
          borderRadius: 10
        }}
      >
        <THView
          style={{
            justifyContent: 'space-between',
            width: '100%',
            gap: 5
          }}
        >
          <TText size="lg">{i18n.t('reponseGenerateOfIA')}</TText>
        </THView>
        <TText
          style={{
            fontWeight: 'bold'
          }}
          size="md"
        >
          {i18n.t('avaliableChannels')}:{' '}
        </TText>
        {activeChannels?.map((channel: Channel) => {
          return (
            <THView
              key={JSON.stringify(channel)}
              bgColor="background"
              style={{
                justifyContent: 'space-between',
                width: '100%',
                gap: 10,
                paddingVertical: 8,
                paddingHorizontal: 16,
                borderRadius: 8
              }}
            >
              <THView
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 5,
                  flexBasis: '70%'
                }}
              >
                <TText
                  style={{
                    textTransform: 'capitalize'
                  }}
                  selectable
                  size="sm"
                >{`${channel?.channel_type}: ${channel.channel_data} ${channel?.channel_label}`}</TText>
                <MaterialCommunity
                  icon={channel?.apollo_ai_enabled === 1 ? Check : IconClose}
                  color={channel?.apollo_ai_enabled === 1 ? '$success' : '$error'}
                  size="md"
                />
              </THView>
              <THView
                style={{
                  justifyContent: 'flex-end',
                  gap: 5
                }}
              >
                {loading[channel?.id] && (
                  <Spinner size={20} animating={loading[channel?.id]} color="$pxsol" />
                )}
                <Switch
                  disabled={loading[channel?.id]}
                  onValueChange={(value) => onSwitchChange(value, channel)}
                  value={channel?.apollo_ai_enabled === 1}
                />
              </THView>
            </THView>
          );
        })}
      </TVView>
    </AcordionCustom>
  );
};

export default memo(ChannelsAutomatizations);
