import axios, { AxiosError, AxiosResponse } from 'axios';
import Constants from 'expo-constants';
import axiosInstance from '@/utils/network/instanceAxios';
import { setAssigneeUsers } from '../contacts';
import {
  clearOutConversationToStateById,
  outConversationToFilter,
  setConversations,
  setFilterStateAndUnreadCount,
  setFragments,
  setIdConversation,
  setNewConversationToState,
  setParticipants,
  setSectionsByConverId,
  setUnreadCountState,
  setUnreadCountToConversation
} from '@/redux/actionTypes';
import {
  CreateBookingInterface,
  Disabled,
  PropsAsscoiateConversation,
  PropsSimpleSearch
} from './interfaces';
import { Thunk } from '@/redux/store';
import { Conversation } from '@/types/conversation';
import { findFragmentByMessageId } from '@/utils/functions';
import { setTemporallyPreviousMessage } from '../messages';
import { Routes } from '@/routers/routes';
import { navigateToConversation } from '@/providers/LinkProvider';
import { setActiveChannels } from '../auth';
import { ALL } from '@/utils/filters';

const { Api2 } = Constants.expoConfig.extra;

export const getAssigneeByTeamId = (team_id: string) => async (dispatch) => {
  try {
    if (!team_id) throw new Error('team_id is required');
    const response = await axiosInstance.get(`/chat/getAssignedByTeam/${team_id}`);

    if (response.status === 200) {
      dispatch(setAssigneeUsers(response?.data));
      return response.data;
    }
    if (response.status === 404) {
      throw new Error(response.data);
    }
  } catch (error) {
    console.error('error getAssigneeByTeamId', error);
    throw error?.response?.data;
  }
};

export const changueApolloConversation =
  (body: { conversation_id: string; change_apollo: boolean }) =>
  async (
    dispatch: (arg0: { payload: { [index: string]: Conversation }; type: string }) => void
  ) => {
    try {
      const response = await axiosInstance.patch(`/apollo/change-apollo-conversation`, body);
      dispatch(setNewConversationToState(response?.data?.data));
      return response.data;
    } catch (error) {
      console.error('error changueApolloConversation', error);
      throw error?.response?.data;
    }
  };

export const activateApolloAI = (props: { channel_id: number; status: boolean }) => async () => {
  try {
    const response = await axiosInstance.patch('/apollo/change-apollo-channel', props);
    return response.data;
  } catch (error) {
    console.error('error activateApolloAI', error);
    throw error?.response?.data;
  }
};

export const moveConversationOfTeam =
  (body: { conversation_id: string; team_id: string }) => async () => {
    try {
      const response = await axiosInstance.patch(`/chat/change-team`, body);
      return response.data;
    } catch (error) {
      console.error('error moveConversationOfTeam', error);
      throw error?.response?.data;
    }
  };

export const getBookingsOfApi2 =
  (props: PropsSimpleSearch): Thunk =>
  async (dispatch, getState) => {
    const state = getState();
    const token = state.user.auth0Token;
    try {
      const config = {
        headers: {
          authorization: `Bearer ${token}`
        }
      };

      const data = await axios.post(`${Api2}/conversations/simple_search`, props, config);
      if (data.data.status == '404') {
        console.error('fallo al obtener: ', data.data);
        // dispatch(setErrorToGetConversation(data.data.msg));
        throw new Error();
      }
      if (data.data?.status == '200') {
        // dispatch(setConversation(data.data.data));
        return data.data.data as AxiosResponse;
      }
      return data.data as AxiosResponse;
    } catch (error) {
      return '404' as unknown as AxiosError;
    }
  };

export const createAndAssociateBookingToConversation =
  (props: CreateBookingInterface) => async () => {
    try {
      const response = await axiosInstance.post('/chat/create-reservation-and-associate', props);
      return response.data;
    } catch (error) {
      console.error(
        '%c [createAndAssociateBookingToConversation] Error Catch...',
        `color:#FFF; background-color: red; padding:15px;`,
        error?.status
      );
      throw error?.response?.data;
    }
  };

export const associateToConversation = (query: PropsAsscoiateConversation) => async (dispatch) => {
  try {
    const data = await axiosInstance.post(`/chat/associate`, query);
    dispatch(setFragments(data.data.data));
    return data.data;
  } catch (error) {
    console.error(
      '%c [associateUserToConversation] Error Catch...',
      `color:#FFF; background-color: red; padding:15px;`,
      error
    );
    throw error?.response?.data;
  }
};

export const associateUsersToConversation =
  (props: { conversationId: string; userIds: number[]; fragmentId: string }) => async () => {
    console.log('associateUsersToConversation', { props });
    try {
      const response = await axiosInstance.post('/chat/assign-fragment', props);
      return response.data;
    } catch (error) {
      console.error('error associateUsersToConversation', error);
      throw error?.response?.data;
    }
  };

export const listChannelsCompany =
  ({ companyId }: { companyId: string }) =>
  async (dispatch) => {
    if (!companyId) return;
    try {
      const response = await axiosInstance.get(`/apollo/list-channels-company/${companyId}`);
      dispatch(setActiveChannels({ companyId, channels: response.data.data }));
      return response.data?.data;
    } catch (error) {
      console.error('error listChannelsCompany', error);
      throw error?.response?.data;
    }
  };

export const disabledNotification =
  ({ id_conversation, status }: Disabled): Thunk =>
  async (): Promise<AxiosResponse<string> | AxiosError<string>> => {
    try {
      const data = await axiosInstance.post(
        `/chat/notificationUpdateByidConversation/${id_conversation}`,
        {
          status
        }
      );

      if (data.data?.status == '404') {
        throw new Error();
      }
      if (data.data?.status == '409') {
        return '409' as unknown as AxiosResponse<string>;
      }
      // dispatch(compareConversation(data.data.conversation));
      return '200' as unknown as AxiosResponse<string>;
    } catch (error) {
      return '404' as unknown as AxiosError<string>;
    }
  };

export const updateLenguageConversation =
  (props: { conversationId: string; language: string }) => async (dispatch) => {
    try {
      const response = await axiosInstance.post('/chat/updateLenguage', props);
      if (response.status === 200) {
        dispatch(setNewConversationToState(response?.data?.response));
        return response.data;
      }
      if (response.status === 404) {
        throw new Error(response.data);
      }
    } catch (error) {
      console.error('error updateLenguageConversation', error);
      throw error;
    }
  };

export const searchMessagesInOmnibox =
  ({ text, page }) =>
  async () => {
    try {
      const response = await axiosInstance.get(`/search/searchMessages/${text}?page=${page}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const searchConversationInOmnibox =
  ({ text, page }) =>
  async () => {
    try {
      const response = await axiosInstance.get(`/search/searchConversation/${text}?page=${page}`);

      if (response?.status === 500) {
        throw response.data;
      }
      return response.data;
    } catch (error) {
      console.error('error searchConversationInOmnibox', error);

      throw error;
    }
  };

export const getConversationById =
  ({ conversationId, idMessage = '' }: { conversationId: string; idMessage?: string }) =>
  async (dispatch) => {
    let idMessageQuery = '';
    if (idMessage) {
      idMessageQuery = `/chat/getConversationById/${conversationId}/${idMessage}`;
    } else {
      idMessageQuery = `/chat/getConversationById/${conversationId}/`;
    }
    try {
      const response = await axiosInstance.get(idMessageQuery);
      return response.data;
    } catch (error) {
      dispatch(setIdConversation(' '));
      dispatch(clearOutConversationToStateById(conversationId));
      return error;
    }
  };

export const initConversationToWhatsapp = (props) => async () => {
  try {
    const response = await axiosInstance.post('/chat/initConversationToWhatsapp', props);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const setConversationWhoNotRead =
  ({ conversationId }: { conversationId: string }) =>
  async () => {
    try {
      const response = await axiosInstance.put('/message/setConversationWhoNotRead', {
        conversationId
      });
      if (response.status === 202) {
        return response.data;
      }
      throw response.data;
    } catch (error) {
      throw error?.response?.data ?? error;
    }
  };

export const getRankerConversations = () => async () => {
  try {
    const response = await axiosInstance.get('/chat/rankerConversations');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getConversAndMessages =
  ({
    id_message,
    id,
    filter,
    navigate = true,
    teamId
  }: {
    id_message?: string;
    id: string;
    filter?: string;
    navigate?: boolean;
    teamId: string;
  }) =>
  async (dispatch) => {
    try {
      dispatch(getConversationById({ conversationId: id, idMessage: id_message }))
        .then(
          (res: {
            data: {
              filter: any;
              section: { [x: string]: any };
              conversation: { [s: string]: any } | ArrayLike<any>;
              participant: any;
              participantes: any;
              fragments: any;
              lastFragmentInfo: any;
            };
          }) => {
            const count = {
              [filter ?? res?.data?.filter]: {
                conversations: [
                  {
                    id,
                    messageCount: res?.data?.section[id]?.length,
                    unreadMessageCount: 0,
                    updatedAt: Object.values<any>(res?.data?.conversation)[0]?.updatedAt
                  }
                ],
                //unread: { [id]: 0 },
                conversationCountWithOpenFragment: 0
              }
            };
            const [previousMessages, currentFragment] = findFragmentByMessageId({
              array: res?.data?.section[id],
              id: id_message
            });
            const participants = id_message ? res?.data?.participant : res?.data.participantes;
            const fragments = id_message ? res?.data.fragments : res?.data?.lastFragmentInfo;
            Promise.all([
              dispatch(setTemporallyPreviousMessage({ [id]: previousMessages })),
              dispatch(
                setConversations({
                  conversations: res?.data?.conversation
                })
              ),
              dispatch(
                setUnreadCountToConversation({
                  conversationId: id,
                  count: 0
                })
              ),
              dispatch(
                setFilterStateAndUnreadCount({
                  filter: filter ?? res?.data?.filter,
                  type:
                    Object.values<any>(res?.data?.conversation)[0]?.platform === 'internal'
                      ? Routes.Chat
                      : Routes.clientes,
                  count,
                  teamId
                })
              ),
              dispatch(setSectionsByConverId({ id, sections: currentFragment })),
              dispatch(setParticipants(participants)),
              // se guradan los fragmentos
              dispatch(setFragments(fragments))
            ]).then((res) => {
              return res;
            });
            // se verifica si la conversacion pertenece al equipo para navegar
            const isConverOfTime =
              Object.values<any>(res?.data?.conversation)[0]?.team_id?.toString() ===
              teamId?.toString();
            if (isConverOfTime) {
              navigate &&
                navigateToConversation({
                  idConver: id,
                  idMessage: id_message,
                  platform:
                    Object.values<any>(res?.data?.conversation)[0]?.platform === 'internal'
                      ? Routes.Chat
                      : Routes.clientes,
                  filter: filter ?? res?.data?.filter
                });
            }
          }
        )
        .catch((err: string) => {
          console.log('err', err);
        });
    } catch (error) {
      throw error?.response?.data ?? error;
    }
  };
