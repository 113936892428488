import React from 'react';
import i18n from '@/providers/Localization';
import { useNavigation } from '@react-navigation/native';
import MainCta from './MainCta';
import { HEIGHT_HEADER_LAYOUT } from '@/utils/globales';
import { IconArrowLeft } from '@/assets/icons/Icons';
import { THView, TText } from '@/components/themed';

function HeaderAppBar({
  idStringTitle,
  ctaBack
}: {
  idStringTitle: string;
  ctaBack?: React.ReactElement;
}) {
  const navigation = useNavigation();

  return (
    <THView
      bgColor="backgroundSecondary"
      border="border"
      style={{
        width: '100%',
        gap: 8,
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottomWidth: 1,
        height: HEIGHT_HEADER_LAYOUT,
        paddingHorizontal: 4
      }}
    >
      {ctaBack ? (
        ctaBack
      ) : (
        <MainCta
          iconSize="md"
          icon={IconArrowLeft}
          variant="outline"
          onPress={() => navigation?.goBack()}
        />
      )}
      <TText numberOfLines={1}>{i18n.t(idStringTitle)}</TText>
    </THView>
  );
}

export default React.memo(HeaderAppBar);
