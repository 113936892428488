import React, { useState } from 'react';

import {
  Popover,
  PopoverArrow,
  PopoverBackdrop,
  PopoverBody,
  PopoverContent,
  PopoverHeader
} from '@gluestack-ui/themed';
import { getDataOfFilters, setLoadingChangeTeam } from '@/redux/slices/auth';
import { setIdConversation, setProgress, setTeamUser } from '@/redux/actionTypes';

import { PropsTeamPicker } from './Interfaces';
import { Pressable } from '@gluestack-ui/themed';
import TextDisabledUpper from '../TextDisabledUpper';
import { Heading } from '@gluestack-ui/themed';
import translate from '@/helpers/traslations';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { ChevronDown, ChevronUp } from '@/assets/icons/Icons';
import { RootState, useAppSelector } from '@/redux/store';
import RenderCompanys from '@/components/partials/renderTeam/RenderCompanys';
import { Team, Teams } from '@/types/typesRedux/interfaces';
import { useRoute } from '@react-navigation/native';
import ThemedScrollView from '@/components/themed/ThemedScrollView';
import { THView, TText, TVView } from '@/components/themed';
import { setAgentId } from '@/redux/slices/apollo';

function TeamPicker({ dispatch, navigation, navigateTo }: PropsTeamPicker): JSX.Element {
  const teams = useAppSelector((state: RootState) => state.user.currenlyTeams);
  const teamUser = useAppSelector((state: RootState) => state.user.team);
  const route = useRoute();
  const channels = useAppSelector((state: RootState) => state.user.channels);
  const userId = useAppSelector((state: RootState) => state.user.user?.id);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelectOrder = (value) => {
    handleChangeTeam(value);
    setIsOpen(false);
  };

  const handleChangeTeam = (selectedItem) => {
    dispatch(setLoadingChangeTeam(true));
    dispatch(setIdConversation(' '));

    //como se cambia de equipo, se debe actualizar el agente por defecto
    if (teams?.length > 0) {
      teams?.forEach((empresa: Teams) => {
        empresa?.teams?.forEach((equipo: Team) => {
          if (equipo?.id === selectedItem?.id) {
            console.log('equipo?.defaultAgentId', equipo?.defaultAgentId);
            dispatch(setAgentId(equipo?.defaultAgentId));
          }
        });
      });
    }

    dispatch(
      setTeamUser({
        id: selectedItem?.id,
        name: selectedItem?.name
      })
    );

    dispatch(
      getDataOfFilters({
        channels,
        teamId: selectedItem?.id,
        userId
      })
    )
      .then(() => {
        dispatch(setProgress(0));
        navigation.reset({
          index: 0,
          routes: [navigateTo]
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch(setProgress(0));
      });
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      width="100%"
      placement="bottom right"
      trigger={(triggerProps) => {
        return (
          <Pressable
            {...triggerProps}
            sx={{
              borderRadius: '$sm',
              width: '100%',
              paddingHorizontal: 4
            }}
          >
            <THView style={{ justifyContent: 'space-between', width: '100%' }}>
              <TVView style={{ flexBasis: '70%' }}>
                <TextDisabledUpper text={translate('team')} />
                <TText numberOfLines={1} size="sm">
                  {teamUser?.name?.split('-')[0]}
                </TText>
              </TVView>
              <MaterialCommunity icon={isOpen ? ChevronUp : ChevronDown} size="md" />
            </THView>
          </Pressable>
        );
      }}
    >
      <PopoverBackdrop />
      <PopoverContent width="100%" bg="$background">
        <PopoverArrow bg="$background" />
        <PopoverHeader width="100%" justifyContent="flex-start">
          <Heading selectable={false} size="sm">
            {translate('messages.teamsAvaliables')}
          </Heading>
        </PopoverHeader>
        <PopoverBody width="100%" paddingHorizontal="$2">
          <ThemedScrollView style={{ maxHeight: 500 }} showsHorizontalScrollIndicator={false}>
            {teams?.length > 0 &&
              teams?.map((company: Teams) => (
                <RenderCompanys
                  key={company?.id_company}
                  item={company}
                  route={route}
                  onPressItem={handleSelectOrder}
                />
              ))}
          </ThemedScrollView>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default React.memo(TeamPicker);
