import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { TODOS } from '@/utils/filters';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';

import { createConversation } from '../redux/slices/conversation';
import LayoutNewConversation from '../layout/LayoutNewConversation';
import { setNewGroup } from '../redux/actionTypes';
import { Routes } from '../routers/routes';
import i18n from '../providers/Localization';
import { verifyIsMobile } from '@/utils/functions';
import { Contacts } from '@/types/typesRedux/interfaces';
import { PropsNewConver } from './interfaces';

function NewConversation({ showToast, navigation, setModalVisible, team }: PropsNewConver) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user.user);
  const id_empresa = useAppSelector((state: RootState) => state.user.company.IDEmpresas);
  const contactos = useAppSelector((state: RootState) => state.contacts.contacto);

  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    dispatch(setNewGroup(false));
  }, []);

  const handleCreateConversation = async (contact: Contacts) => {
    setLoading(true);
    if (contact?.id_user === user?.id) return;

    dispatch(
      createConversation(TODOS, {
        status: 'new',
        participants: [
          {
            userId: user?.idMongoUser,
            isOwner: true,
            hasNotification: true
          },
          {
            userId: contact?._id,
            isOwner: false,
            hasNotification: true
          }
        ],
        company_id: `${id_empresa}`,
        team_id: `${team}`,
        platform: 'internal',
        getMessages: false,
        group: {
          status: false,
          name: '',
          avatar: ''
        },
        id_channel: ''
      })
    )
      .then((res: unknown) => {
        if (res === '404') {
          showToast({
            type: 'error',
            title: i18n.t('error'),
            subtitle: i18n.t('messageError.noCreateConversation')
          });
          setLoading(false);
          return;
        }

        setModalVisible(false);
        if (Platform.OS === 'web') {
          !verifyIsMobile()
            ? navigation.reset({
                index: 0,
                routes: [
                  {
                    name: Routes.Conversation,
                    params: {
                      id: res,
                      filter: TODOS
                    }
                  }
                ]
              })
            : navigation.navigate(Routes.ConversationStack, {
                id: res as string,
                filter: TODOS
              });
        } else {
          navigation.navigate(Routes.ConversationStack, {
            id: res as string,
            filter: TODOS
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
        showToast({
          type: 'error',
          title: i18n.t('error'),
          subtitle: i18n.t('messageError.noCreateConversation')
        });
      });
  };

  return (
    <LayoutNewConversation
      contacto={contactos}
      handleSelect={handleCreateConversation}
      newConversation
      loading={loading}
      idStringInfo="selectContactToInitInternalConversation"
    />
  );
}
export default React.memo(NewConversation);
