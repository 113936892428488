import { Message } from '@/types/message';
import { Contacts } from '@/types/typesRedux/interfaces';

export interface PropsMessageComponent {
  readonly message: Message;
  // readonly picture: any;
  setReplyInfo?: (value: null | Message) => void;
  readonly IconReply?: PositionMessage;
  readonly from: Contacts | null;
  readonly showSender: boolean;
}

export interface HeaderMessageComponentInterface {
  name: string;
  text: string;
  avatar: string;
  messageId: string;
  conversationId: string;
  showIconChevron?: boolean;
  setDataBc?: (value: any) => void;
}

export interface PropsHeaderMessageMapView {
  latitude: number;
  longitude: number;
  url: string;
  draggable?: boolean;
  myPosition?: boolean;
}

export enum PositionMessage {
  LEFT = 'left',
  RIGHT = 'right'
}
