import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import Constants from 'expo-constants';
import { getReduxStore } from '../../redux/store';

const { backendUrl, Api2, apollo } = Constants.expoConfig.extra;

const URL_BASE = backendUrl ?? 'https://conversations-back-prod.pxsol.io';

export let cancelTokens = [];

export const createAxiosInstance = (
  baseUrl = URL_BASE,
  additionalHeaders?: AxiosRequestHeaders
): AxiosInstance => {
  const instance = axios.create({ baseURL: baseUrl });

  instance.interceptors.request.use((config) => {
    const tokenAuth0 = getReduxStore().user?.auth0Token || '';

    // Combinar headers
    config.headers.set('Accept', 'application/json, text/plain, multipart/form-data, */*');
    config.headers.set('Authorization', `Bearer ${tokenAuth0}`);
    if (additionalHeaders) {
      Object.keys(additionalHeaders).forEach((key) => {
        config.headers.set(key, additionalHeaders[key]);
      });
    }

    // Usar axios.CancelToken para crear un nuevo token y función de cancelación
    const { cancel, token } = axios.CancelToken.source();
    cancelTokens.push(cancel); // Añadir la función cancel al array
    config.cancelToken = token; // Asignar el token de cancelación al config

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      // Implementación opcional para remover el token cancelado del array
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        console.log('Unauthorized error', error.response);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

const cancelAllRequests = () => {
  console.log('Cancelando todas las peticiones', cancelTokens);

  cancelTokens.forEach((cancel) => cancel('Petición cancelada por el usuario'));
  cancelTokens = []; // Limpiar el array después de cancelar
};

const axiosInstance = createAxiosInstance();
const axiosInstanceApi2 = createAxiosInstance(Api2);
const axiosEmbeddings = createAxiosInstance(apollo);

export { axiosInstanceApi2, axiosEmbeddings, cancelAllRequests };
export default axiosInstance;
