import { colorsProps, useThemeColor } from '@/hooks/useTheme';
import React, { useEffect } from 'react';
import { DimensionValue } from 'react-native';
import { StyleSheet } from 'react-native';
import Animated, {
  Easing,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
  useAnimatedStyle
} from 'react-native-reanimated';

type SkeletonProps = {
  type: 'circle' | 'rectangle' | 'square' | 'text';
  size?: number;
  width?: DimensionValue;
  height?: DimensionValue;
  background?: colorsProps;
  lightColor?: string;
  darkColor?: string;
};

const CustomSkeleton: React.FC<SkeletonProps> = ({
  type,
  size,
  width,
  height,
  background,
  lightColor,
  darkColor
}) => {
  const fadeAnim = useSharedValue(0.5);

  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, background);
  useEffect(() => {
    fadeAnim.value = withRepeat(
      withSequence(
        withTiming(1, { duration: 700, easing: Easing.inOut(Easing.ease) }),
        withTiming(0.5, { duration: 700, easing: Easing.inOut(Easing.ease) })
      ),
      -1,
      true
    );
  }, [fadeAnim]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: fadeAnim.value
    };
  });

  let skeletonStyle;

  const styles = StyleSheet.create({
    skeleton: {
      backgroundColor,
      borderRadius: 5,
      marginVertical: 5,
      alignSelf: 'center'
    }
  });

  switch (type) {
    case 'circle':
      skeletonStyle = { width: size, height: size, borderRadius: size / 2 };
      break;
    case 'rectangle':
      skeletonStyle = { width, height };
      break;
    case 'square':
      skeletonStyle = { width: size, height: size };
      break;
    case 'text':
      skeletonStyle = { width, height };
      break;
    default:
      skeletonStyle = {};
  }

  return <Animated.View style={[styles.skeleton, skeletonStyle, animatedStyle]} />;
};

export default CustomSkeleton;
