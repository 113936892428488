import { Eye } from '@/assets/icons/Icons';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { THView, TText } from '@/components/themed';
import { colorsProps } from '@/hooks/useTheme';
import React, { memo, useMemo } from 'react';
import { StyleSheet } from 'react-native';

const OnlyPxsol = ({ bgColor = 'backgroundSecondary' }: { bgColor?: colorsProps }) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          alignItems: 'center',
          gap: 5,
          borderRadius: 16,
          paddingHorizontal: 8
        },
        text: {
          color: '#cc3030'
        }
      }),
    []
  );
  return (
    <THView bgColor={bgColor} style={styles.container}>
      <MaterialCommunity icon={Eye} color="$pxsol" size="md" />
      <TText style={styles.text}>PX</TText>
    </THView>
  );
};

export default memo(OnlyPxsol, (prevProps, nextProps) => {
  return prevProps.bgColor === nextProps.bgColor;
});
