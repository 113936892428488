import React, { memo, useState } from 'react';
import AcordionCustom from '@/atoms/AcordionCustom';
import { InfoOutline, MessageBadge, Web } from '@/assets/icons/Icons';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import translate from '@/helpers/traslations';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import CardCustom from '@/atoms/CardCustom';
import { Platform } from 'react-native';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { updateNotification } from '@/redux/slices/auth/requests';
import { IUpdateNotification } from '@/redux/slices/auth/interfaces.d';
import { useLinkss } from '@/providers/LinkProvider';

const { useNotifications } = Platform.select({
  native: () => require('@/providers/NotificationProvider.native'),
  default: () => require('@/providers/NotificationProvider.web')
})();

const BrowserNotifications = () => {
  const dispatch = useAppDispatch();
  const statusNotification = useAppSelector(
    (state: RootState) => state.user.notifications_config?.status_notification
  );
  const [updatingNotification, setUpdatingNotification] = useState<boolean>(false);
  const [state, setState] = useState<boolean>(statusNotification);

  const { toggleNotifications } = useNotifications();
  const { showToast } = useLinkss();

  const toogleNotificationsBrowser = () => {
    setUpdatingNotification(true);
    setState(!statusNotification);
    dispatch(
      updateNotification({
        propertyNotification: IUpdateNotification.statusNotification,
        data: !statusNotification
      })
    )
      .then((response) => {
        toggleNotifications();
        showToast({
          type: 'success',
          title: translate('success'),
          subtitle: translate('messages.successToUpdateStatus'),
          variant: 'accent'
        });
      })
      .catch((error) => {
        setState(!statusNotification);
        showToast({
          type: 'error',
          title: translate('error'),
          subtitle: translate('messageError.errorToUpdateStatus'),
          variant: 'accent'
        });
      })
      .finally(() => {
        setUpdatingNotification(false);
      });
  };

  return (
    <AcordionCustom
      iconLeft={Web}
      idString="notification.browser"
      titleSize="lg"
      bgColor="$background2"
      mbItem="$0"
      defaultOpen={true}
      sx={{
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginBottom: 20,
        borderRadius: 8
      }}
      middleElement={
        <CustomTooltip title={translate('notification.browserTooltip')}>
          <MaterialCommunity icon={InfoOutline} size="md" color="$info" />
        </CustomTooltip>
      }
    >
      <CardCustom
        idString="notification.browser"
        leftElement={<MaterialCommunity icon={Web} color="$text" size="xl" />}
        idStringSubtitle="notification.browserSubtitle"
        switchProp={{
          value: state,
          onChange: () => toogleNotificationsBrowser(),
          disabled: updatingNotification
        }}
      />
    </AcordionCustom>
  );
};

export default memo(BrowserNotifications);
