import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Ticket } from './interfaces';

interface TypeStateTicket {
  body: { [conversationId: string]: Ticket };
}

const initialState: TypeStateTicket = {
  body: {}
};

//Estado de redux sin persistencia (cuando se recarga se pierde)
export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    setTicketBody: (state, action: PayloadAction<{ id: string; ticket: any }>) => {
      state.body[action.payload.id] = {
        ...state.body?.[action.payload.id],
        ...action.payload?.ticket
      };
    },
    deleteTicketBody: (state, action: PayloadAction<string>) => {
      delete state.body[action.payload];
    }
  }
});

export const { setTicketBody, deleteTicketBody } = ticketSlice.actions;

export default ticketSlice.reducer;
