import React, { memo } from 'react';
import translate from '@/helpers/traslations';
import { InfoOutline } from '@/assets/icons/Icons';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { THView, TText, TVView } from '@/components/themed';
import { colorsProps } from '@/hooks/useTheme';
import { TTextSize } from '@/components/themed/interface';
import { StyleProp, TextStyle } from 'react-native';

const LayoutForm = ({
  children,
  icon,
  idString,
  idStringTooltip,
  leftIcon,
  color = 'text',
  styleText = {},
  size = 'md'
}: {
  children: React.ReactNode;
  icon?: boolean;
  idString: string;
  idStringTooltip?: string;
  leftIcon?: React.ReactNode;
  color?: colorsProps;
  styleText?: StyleProp<TextStyle>;
  size?: TTextSize;
}) => {
  return (
    <TVView style={{ gap: 8 }}>
      <THView
        style={{ alignItems: 'center', gap: 8, width: '100%', justifyContent: 'space-between' }}
      >
        <THView style={{ alignItems: 'center', gap: 4, justifyContent: 'center' }}>
          <TText color={color} size={size} style={styleText}>
            {translate(idString)}
          </TText>
          {icon && (
            <CustomTooltip title={translate(idStringTooltip)}>
              <MaterialCommunity icon={InfoOutline} size="md" color="$info" />
            </CustomTooltip>
          )}
        </THView>
        {leftIcon}
      </THView>
      {children}
    </TVView>
  );
};

export default memo(LayoutForm);
