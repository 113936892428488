import { TText, TVView } from '@/components/themed';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

const SectionFrom = ({
  title,
  idStringSubtitle,
  children,
  style
}: {
  title: string;
  idStringSubtitle: string;
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}) => {
  return (
    <TVView
      bgColor="backgroundSecondary"
      style={[
        {
          gap: 8,
          padding: 16,
          borderRadius: 8,
          marginBottom: 4
        },
        style
      ]}
    >
      <TVView style={{ justifyContent: 'flex-start', alignItems: 'flex-start', marginVertical: 2 }}>
        <TText size="lg" style={{ fontWeight: 'bold' }}>
          {title}
        </TText>
        <TText size="md" color="secondary">
          {idStringSubtitle}
        </TText>
      </TVView>
      {children}
    </TVView>
  );
};

export default React.memo(SectionFrom);
