import { DimensionValue, StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import MemoizedAvatarCard from './AvatarCard';
import { THView, TText, TView, TVView } from '@/components/themed';
import { Check } from '@/assets/icons/Icons';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { Divider } from '@gluestack-ui/themed';

interface PropsContactCard {
  name: string;
  avatar: string;
  email?: string;
  middle?: React.ReactNode;
  rightElement?: React.ReactNode;
  phones?: React.ReactNode;
  variant?: 'accent' | 'solid';
  emails?: React.ReactNode;
  selected?: boolean;
  phone?: string;
  height?: DimensionValue;
}

function ContactCard({
  name,
  avatar,
  email,
  middle,
  rightElement,
  phones,
  variant,
  emails,
  selected,
  phone,
  height
}: PropsContactCard) {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          justifyContent: 'flex-start',
          marginVertical: 2
        },
        user: {
          height: height,
          alignItems: 'center',
          justifyContent: 'space-between',
          space: 'sm',
          flex: 1
        },
        leftContainer: {
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: rightElement ? 8 : 0,
          paddingHorizontal: 8,
          paddingVertical: 4,
          gap: 4,
          flex: 1
        },
        containerUser: {
          alignItems: 'center',
          gap: 8,
          // maxWidth: middle ? '85%' : '100%',
          paddingRight: 10,
          flex: 1,
          overflow: 'hidden'
        },
        nameEmail: {
          flex: 1,
          gap: 4,
          flexWrap: 'wrap'
        },
        selected: { alignItems: 'center', gap: 2, width: 40, height: 40, justifyContent: 'center' }
      }),
    [variant, height, middle]
  );

  return (
    <TVView style={styles.container}>
      <THView style={styles.leftContainer}>
        <THView style={styles.user}>
          <THView style={styles.containerUser}>
            {selected ? (
              <TView style={styles.selected}>
                <MaterialCommunity size="md" icon={Check} />
              </TView>
            ) : (
              <MemoizedAvatarCard picture={avatar || avatar} width="$10" name={name} />
            )}
            <TVView style={styles.nameEmail}>
              <THView
                style={{
                  flex: 1
                }}
              >
                <TText
                  size="md"
                  lineBreakMode="clip"
                  numberOfLines={1}
                  style={{
                    fontWeight: 'bold',
                    width: '100%'
                  }}
                >
                  {name?.trim()}
                </TText>
                {middle && middle}
              </THView>
              <THView
                style={{
                  flexWrap: 'wrap',
                  flex: 1,
                  gap: 4
                }}
              >
                {email && (
                  <TText lineBreakMode="clip" numberOfLines={1} size="md">
                    {email?.trim()}
                  </TText>
                )}
                {phone && (
                  <TText lineBreakMode="clip" numberOfLines={1} size="md">
                    {phone?.trim()}
                  </TText>
                )}
                {emails}
                {phones}
              </THView>
            </TVView>
          </THView>
        </THView>
        {rightElement && rightElement}
      </THView>
      <Divider />
    </TVView>
  );
}

export default React.memo(ContactCard);
