import React, { useMemo } from 'react';
import translate from '@/helpers/traslations';
import InputComponent from '../conversation/footer/InputComponent';
import { StyleSheet } from 'react-native';
import { TPressable, TView } from '../themed';
import ModalSearch from './ModalSearch';
import { InputSlot } from '@gluestack-ui/themed';
import MainCta from '@/atoms/MainCta';
import { Mangify } from '@/assets/icons/Icons';

function SearchComponent() {
  const [modalVisible, setModalVisible] = React.useState(false);
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flex: 1,
          width: '100%'
        },
        pressable: {
          alignSelf: 'flex-start',
          maxWidth: 500,
          height: 40,
          minWidth: 100,
          width: '100%',
          marginVertical: 5,
          borderRadius: 8
        }
      }),
    []
  );

  return (
    <TView style={styles.container}>
      <TPressable onPress={() => setModalVisible(true)} style={styles.pressable}>
        <InputComponent
          multiline={false}
          placeholder={translate('search')}
          value=""
          autofocus={false}
          disabled
          inputHeight={20}
          leftComponent={
            <InputSlot flexDirection="row" gap="$1">
              <MainCta iconColor="$iconos" icon={Mangify} iconSize="md" variant="outline" />
            </InputSlot>
          }
        />
      </TPressable>
      <ModalSearch modalVisible={modalVisible} setModalVisible={setModalVisible} />
    </TView>
  );
}

export default React.memo(SearchComponent);
