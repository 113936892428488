import React, { useMemo } from 'react';
import { RootState, useAppSelector } from '../redux/store';
import TextFormatter from './TextFormatter';
import { LastMessageProps } from './interfaces';
import translate from '@/helpers/traslations';
import { THView, TView } from '@/components/themed';
import { StyleSheet } from 'react-native';

function LastMessage({ message, isGroup }: LastMessageProps) {
  const userEmail = useAppSelector((state: RootState) => state.user.user.email);
  const conversationId = useMemo(() => message?.id_conversation, [message]);

  const unread = useAppSelector(
    (state: RootState) => state.conversations?.unread?.[conversationId] ?? 0
  );

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: { width: '100%', alignItems: 'center', justifyContent: 'space-between', gap: 4 },
        containerOwner: { width: '100%', justifyContent: 'flex-start', alignItems: 'center' }
      }),
    []
  );
  return (
    <THView style={styles.container}>
      {message?.from?.email === userEmail ? (
        <THView style={styles.containerOwner}>
          <TextFormatter numberOfLines={1} color={unread === 0 ? 'secondary' : 'text'}>
            {isGroup && `${message?.from?.name?.trim()}: `}
          </TextFormatter>
          <TextFormatter numberOfLines={1} color={unread === 0 ? 'secondary' : 'text'}>
            {message?.content?.text?.slice(0, 40) ?? translate('noContent')}
          </TextFormatter>
        </THView>
      ) : (
        <TView>
          <TextFormatter numberOfLines={1} color={unread === 0 ? 'secondary' : 'text'}>
            {isGroup && `${message?.from?.name?.trim()}: `}
          </TextFormatter>
          <TextFormatter numberOfLines={1} color={unread === 0 ? 'secondary' : 'text'}>
            {message?.content?.text?.slice(0, 40) ?? translate('noContent')}
          </TextFormatter>
        </TView>
      )}
    </THView>
  );
}

export default React.memo(LastMessage);
