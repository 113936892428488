import { createSlice } from '@reduxjs/toolkit';
import { TemplateInterface } from './interfaces';
import { setNoTemplates, setWorflowTemplates } from '../../actionTypes';

const initialState: TemplateInterface = {
  templates: [],
  workflowTemplates: {},
  cachedTemplates: {},
  sendingTemplates: {},
  idStringCached: '',
  noTemplates: {}
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    cleanTemplates: (state) => {
      state.templates = [];
      state.cachedTemplates = {};
      state.sendingTemplates = {};
    },
    setCachedTemplates: (state, action) => {
      if (action.payload.idString?.trim()) {
        state.cachedTemplates = {
          ...state.cachedTemplates,
          [action.payload.idString]: action.payload.templates
        };
      }
    },
    setSendingTemplates: (state, action) => {
      state.sendingTemplates = {
        ...state.sendingTemplates,
        [action.payload.templateId]: action.payload.status
      };
    },
    deleteAllSendingTemplates: (state) => {
      state.sendingTemplates = {};
    },
    setIdStringCached: (state, action) => {
      state.idStringCached = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setWorflowTemplates, (state, action) => {
      console.log('setWorflowTemplates', action.payload);

      state.workflowTemplates = {
        ...state.workflowTemplates,
        [action.payload.idStringWorkflow]: action.payload.templates
      };
    });
    builder.addCase(setNoTemplates, (state, action) => {
      const { idString, status } = action.payload;

      if (idString?.trim()) {
        state.noTemplates = {
          ...state.noTemplates,
          [idString]: status
        };
      }
    });
  }
});

export const {
  setTemplates,
  cleanTemplates,
  setCachedTemplates,
  setSendingTemplates,
  setIdStringCached,
  deleteAllSendingTemplates
} = templatesSlice.actions;

export default templatesSlice.reducer;
