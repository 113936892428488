import React, { useEffect, useMemo, useState } from 'react';
import { GluestackUIProvider } from '@gluestack-ui/themed';
import { StyledProvider } from '@gluestack-style/react';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getThemeConfig } from '../../config/gluestack-style.config';
import { RootState } from '@/redux/store';
import { ThemeMode } from '@/types/theme';
import { setThemeMode } from '@/redux/actionTypes';

import { locale } from 'moment';

const currentLocale = locale();

import { enGB, registerTranslation } from 'react-native-paper-dates';
import i18n from './Localization';
import DialogComponent from '@/components/DialogComponent';
import MainCta from '@/atoms/MainCta';
import { IconAdd, IconArrowRight } from '@/assets/icons/Icons';
import { getDialogText } from '@/utils/functions';
import { Linking } from 'react-native';
import { URL_TO_CONNECT_CHANNEL, URL_TO_CREATE_WIDGET } from '@/utils/constants';
import { setDialogChannels } from '@/redux/slices/theme';
import { DarkTheme, ThemeProvider, DefaultTheme } from '@react-navigation/native';

switch (currentLocale) {
  case 'en':
    registerTranslation('en-GB', enGB);
    break;
  case 'es':
    registerTranslation('es', {
      save: i18n.t('save'),
      selectSingle: i18n.t('selectSingle'),
      selectMultiple: i18n.t('selectMultiple'),
      selectRange: i18n.t('selectRange'),
      notAccordingToDateFormat: (inputFormat) => `Date format must be ${inputFormat}`,
      mustBeHigherThan: (date) => `Must be later then ${date}`,
      mustBeLowerThan: (date) => `Must be earlier then ${date}`,
      mustBeBetween: (startDate, endDate) => `Must be between ${startDate} - ${endDate}`,
      dateIsDisabled: i18n.t('Dayisnotallowed'),
      previous: i18n.t('actions.previous'),
      next: i18n.t('next'),
      typeInDate: i18n.t('typeInDate'),
      pickDateFromCalendar: i18n.t('pickDateFromCalendar'),
      close: i18n.t('close'),
      hour: '',
      minute: ''
    });
    break;
  default:
    registerTranslation('es', {
      save: i18n.t('save'),
      selectSingle: i18n.t('selectSingle'),
      selectMultiple: i18n.t('selectMultiple'),
      selectRange: i18n.t('selectRange'),
      notAccordingToDateFormat: (inputFormat) => `Date format must be ${inputFormat}`,
      mustBeHigherThan: (date) => `Must be later then ${date}`,
      mustBeLowerThan: (date) => `Must be earlier then ${date}`,
      mustBeBetween: (startDate, endDate) => `Must be between ${startDate} - ${endDate}`,
      dateIsDisabled: i18n.t('Dayisnotallowed'),
      previous: i18n.t('actions.previous'),
      next: i18n.t('next'),
      typeInDate: i18n.t('typeInDate'),
      pickDateFromCalendar: i18n.t('pickDateFromCalendar'),
      close: i18n.t('close'),
      hour: '',
      minute: ''
    });
}

type Props = {
  children: React.ReactNode;
};

const ThemeContext = React.createContext<{
  themeMode: ThemeMode;
  toggleTheme: () => void;
}>(null);
export const useChangeTheme = () => React.useContext(ThemeContext);

function CustomThemeProvider({ children }: Props) {
  const dispatch = useAppDispatch();
  const themeMode: ThemeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const dialogChannel = useAppSelector((state: RootState) => state.settings.dialogChannels);
  // Obtener la configuración del tema actual basado en el estado de Redux

  const currentThemeConfig = useMemo(() => getThemeConfig(themeMode), [themeMode]);

  const toggleTheme = () => {
    const newTheme: ThemeMode = themeMode === 'light' ? 'dark' : 'light';
    // setCurrentThemeConfig(getThemeConfig(newTheme));
    dispatch(setThemeMode(newTheme));
  };

  const hiddeDialog = (bool: boolean) => {
    dispatch(setDialogChannels({ status: bool, data: {} }));
  };

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      <GluestackUIProvider colorMode={themeMode} config={currentThemeConfig}>
        <StyledProvider colorMode={themeMode} config={currentThemeConfig}>
          <ThemeProvider value={themeMode === 'dark' ? DarkTheme : DefaultTheme}>
            {children}
            <DialogComponent
              dialogVisible={dialogChannel?.status}
              setDialogVisible={hiddeDialog}
              idStringTitle={
                dialogChannel?.data?.available
                  ? `isConnect${dialogChannel?.data?.name}DialogTitle`
                  : `connect${dialogChannel?.data?.name}DialogTitle`
              }
              idStringDescription={
                dialogChannel?.data?.available
                  ? `isConnect${dialogChannel?.data?.name}DialogDescription`
                  : `connect${dialogChannel?.data?.name}DialogDescription`
              }
              Cta={
                <MainCta
                  icon={IconAdd}
                  variant="solid"
                  iconPosition="left"
                  iconSize="xs"
                  labelId={getDialogText(dialogChannel?.data)}
                  onPress={() => {
                    Linking.openURL(
                      dialogChannel?.data?.name === 'widget'
                        ? URL_TO_CREATE_WIDGET
                        : URL_TO_CONNECT_CHANNEL
                    );
                    hiddeDialog(false);
                  }}
                />
              }
            />
          </ThemeProvider>
        </StyledProvider>
      </GluestackUIProvider>
    </ThemeContext.Provider>
  );
}

export default React.memo(CustomThemeProvider);
