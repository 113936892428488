import { setIdMongoTo } from '@/redux/actionTypes';
import { TypeUserParticipant } from '@/redux/slices/participants/interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface TypeStateApollo {
  user: string;
  agent: string;
  agent_id: string;
  idMongoTo: TypeUserParticipant;
  showDots: boolean;
}

const initialState: TypeStateApollo = {
  user: '',
  agent: '',
  agent_id: '',
  idMongoTo: {} as TypeUserParticipant,
  showDots: false
};

export const apolloSlice = createSlice({
  name: 'apollo',
  initialState,
  reducers: {
    setAgent: (state, action: PayloadAction<string>) => {
      state.agent = action.payload;
    },
    setUser: (state, action: PayloadAction<string>) => {
      state.user = action.payload;
    },
    setAgentId: (state, action: PayloadAction<string>) => {
      if (action.payload !== state.agent_id) {
        state.agent_id = action.payload;
      }
    },
    setShowDots(state, action) {
      state.showDots = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setIdMongoTo, (state, action) => {
      state.idMongoTo = action.payload;
    });
  }
});

export const { setAgent, setUser, setAgentId, setShowDots } = apolloSlice.actions;

export default apolloSlice.reducer;
