import { BadgeText, Pressable, View } from '@gluestack-ui/themed';
import { HStack } from '@gluestack-ui/themed';
import React, { useMemo, useState } from 'react';
import { ChevronDown, IconDrag, Pencil, Trash } from '@/assets/icons/Icons';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { Text } from '@gluestack-ui/themed';
import { Switch } from '@gluestack-ui/themed';
import MenuActions from '@/components/menu/MenuActions';
import translate from '@/helpers/traslations';
import { navigation } from '@/routers/router';
import { Routes } from '@/routers/routes';
import { IS_WEB_PC } from '@/utils/functions';
import { useCollapsed } from '@/providers/CollpasedProvider';
import { useLinkss } from '@/providers/LinkProvider';
import { useAppDispatch, useAppSelector } from '@/redux/store/index';
import { setCreatingWorkflow } from '@/redux/actionTypes/index';
import { Badge } from '@gluestack-ui/themed';
import RenderBadges from '@/atoms/RenderBadges';
import { findIdChannelValue } from '@/redux/slices/automatization/functions';
import { changeStatusWorkflow, deleteWorkflow } from '@/redux/slices/automatization/requests';
import { setDatesRanges, updateWorkflow } from '@/redux/slices/automatization/index';
import DialogComponent from '@/components/DialogComponent';
import MainCta from '@/atoms/MainCta';
import { Workflow } from '@/components/automatizations/interface';
import { setMenuRigth } from '@/redux/slices/theme';
import CardCustom from '@/atoms/CardCustom';

const ItemAutomatization = ({
  isActive,
  item,
  drag,
  channels
}: {
  isActive: boolean;
  item: Workflow;
  drag: () => void;
  channels: any;
}) => {
  const dispatch = useAppDispatch();
  const idStringWorkflow = useAppSelector((state) => state.automatization.idStringWorkflow);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>();

  const { showToast } = useLinkss();
  const [disabledSwitch, setDisabledSwitch] = React.useState(false);

  const handleEdtiAutomatization = () => {
    const {
      __v,
      updatedAt,
      createdAt,
      clonedDate,
      clonedFromEva,
      deletedAt,
      _id,
      ...itemWithoutKeys
    } = item;

    dispatch(setDatesRanges([]));
    dispatch(setCreatingWorkflow(itemWithoutKeys));

    if (item?.actions[0]?.parameters?.response_type === 'template') {
      if (IS_WEB_PC) {
        dispatch(setMenuRigth(true));
        setTimeout(() => {
          navigation(Routes?.AutomatizationTemplate, { itemId: item?._id });
        }, 100);
      } else {
        navigation(Routes?.AutomatizationTemplate, { itemId: item?._id });
      }
    } else {
      if (IS_WEB_PC) {
        dispatch(setMenuRigth(true));
        setTimeout(() => {
          navigation(Routes?.AutomatizationBot, { itemId: item?._id });
        }, 100);
      } else {
        navigation(Routes?.AutomatizationBot, { itemId: item?._id });
      }
    }
  };

  const handleDeleteAutomatization = () => {
    setDeleting(true);
    dispatch(deleteWorkflow({ id: item._id, idString: idStringWorkflow }))
      .then(() => {
        setDeleting(false);
        showToast({
          type: 'success',
          subtitle: translate('automatizations.deletedAutomatization'),
          variant: 'accent'
        });
      })
      .catch((error) => {
        setDeleting(false);
        console.error('error to delete workflow', error);
        showToast({
          type: 'error',
          title: translate('error'),
          subtitle: translate('automatizations.errorToDeleteAutomatization'),
          variant: 'accent'
        });
      })
      .finally(() => {
        setDialogVisible(false);
      });
  };

  const menuActions = [
    {
      label: 'actions.edit',
      icon: Pencil,
      action: () => handleEdtiAutomatization(),
      key: 'editAutomatization',
      available: true
    },
    {
      label: 'actions.delete',
      icon: Trash,
      action: () => setDialogVisible(true),
      key: 'deleteAutomatization',
      available: true
    }
  ];

  const itemValues = useMemo(() => findIdChannelValue(item), [item]);

  const handleChangeStatus = (item) => {
    setDisabledSwitch(true);
    dispatch(changeStatusWorkflow({ id: item._id, status: !item.enabled }))
      .then((response) => {
        dispatch(updateWorkflow({ idString: idStringWorkflow, workflow: response?.data }));
        showToast({
          type: 'success',
          title: translate('success'),
          subtitle: translate('messages.successToUpdateStatus'),
          variant: 'accent'
        });
      })
      .catch((error) => {
        showToast({
          type: 'error',
          title: translate('error'),
          subtitle: translate('messageError.errorToUpdateStatus'),
          variant: 'accent'
        });
      })
      .finally(() => {
        setDisabledSwitch(false);
      });
  };

  return (
    <HStack>
      <CardCustom
        idString={item?.name}
        translateText={false}
        switchProp={{
          value: item?.enabled,
          onChange: () => handleChangeStatus(item),
          disabled: disabledSwitch
        }}
        sx={{
          paddingLeft: 0,
          paddingRight: 4
        }}
        middleElement={
          itemValues?.length > 0 ? (
            <RenderBadges
              badges={itemValues}
              data={channels}
              findKey="channel_id"
              iconKey="channel_type"
              showKey="channel_data"
              secondShowKey="channel_type"
              size="2xs"
              numbers={1}
            />
          ) : (
            <Badge variant="solid" action="error" gap="$1">
              <BadgeText textTransform="capitalize" color="$text">
                canal no definido
              </BadgeText>
            </Badge>
          )
        }
        leftElement={
          <Pressable
            style={{
              height: '100%',
              width: 30,
              alignItems: 'center',
              justifyContent: 'center',
              borderTopStartRadius: 8,
              borderBottomStartRadius: 8
            }}
            sx={{
              _web: {
                cursor: 'grab'
              }
            }}
            onLongPress={drag}
            delayLongPress={0}
          >
            <MaterialCommunity icon={IconDrag} size="lg" color="white" />
          </Pressable>
        }
        actions={
          <View maxWidth="70%">
            <MenuActions
              menuActions={menuActions}
              sx={{ width: '100%' }}
              trigger={
                <HStack
                  width="100%"
                  justifyContent="center"
                  space="xs"
                  alignItems="center"
                  paddingHorizontal="$2"
                >
                  <MaterialCommunity icon={ChevronDown} size="md" color="$text" />
                  {IS_WEB_PC && <Text color="$text">{translate('actions.actions')}</Text>}
                </HStack>
              }
            />
          </View>
        }
      />

      <DialogComponent
        dialogVisible={dialogVisible}
        setDialogVisible={setDialogVisible}
        idStringTitle="automatizations.deleteAutomatization"
        idStringDescription="automatizations.deleteAutomatizationDescription"
        Cta={
          <MainCta
            disabled={deleting}
            loading={deleting}
            iconSize="xs"
            labelId="actions.delete"
            action="primary"
            onPress={() => handleDeleteAutomatization()}
          />
        }
      />
    </HStack>
  );
};

export default React.memo(ItemAutomatization);
