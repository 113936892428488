import { StyleSheet } from 'react-native';
import React, { useMemo, useState } from 'react';
import i18n from '@/providers/Localization';
import MemoizedAvatarCard from './AvatarCard';
import MaterialCommunity from '../components/Icons/MaterialCommunity';
import MenuDots from './itemSidebar/MenuDots';
import { ItemDrawerSettingsProps } from './interfaces';
import { TPressable, TText, TView } from '@/components/themed';
import { Platform } from 'react-native';

function ItemDrawerSettings({
  style,
  onPress,
  idString,
  icon,
  w = 'md',
  absolute = false,
  direction = 'row',
  badge,
  iconRight,
  noAvaliable = false,
  color = 'text',
  translate = true,
  svg = false,
  name = 'NA',
  paddingRight = 0,
  bgAvatar = false,
  collapsed = false,
  dosVertical = false,
  id
}: ItemDrawerSettingsProps) {
  const [display, setDisplay] = useState<boolean>(false);
  const [isMenuHovered, setIsMenuHovered] = useState<boolean>(false);
  const [itemIsHovered, setItemIsHovered] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState(false);

  const onCloseMenu = () => {
    setIsMenuHovered(false);
    setOpenMenu(false);
    setItemIsHovered(false);
    setDisplay(false);
  };

  const onHoverOut = () => {
    if (isMenuHovered && !openMenu && itemIsHovered && display) setDisplay(false);
    if (openMenu || isMenuHovered) return;
    setDisplay(false);
  };

  const onHoverOutDots = () => {
    if (openMenu || itemIsHovered) return;
    setIsMenuHovered(false);
    setDisplay(false);
  };

  const onHoverInDots = () => {
    setDisplay(true);
    setIsMenuHovered(true);
  };

  const onHoverIn = () => {
    if (collapsed) return;
    // console.log(idString, 'hoverInItem');
    setItemIsHovered(true);
    setIsMenuHovered(false);
    setDisplay(true);
  };

  const styles = useMemo(
    () =>
      StyleSheet.create({
        pressable: {
          width: collapsed ? 43 : '100%',
          position: 'relative',
          paddingVertical: 8,
          paddingHorizontal: 4,
          marginVertical: 2,
          borderRadius: 8,
          borderWidth: noAvaliable ? 2 : 0,
          borderStyle: 'dashed',
          ...Platform.select({
            web: {
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'red'
              }
            }
          })
        },
        children1: {
          width: '100%',
          paddingRight,
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          display: 'flex'
        },
        leftComponent: {
          display: 'flex',
          flexDirection: direction,
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 3,
          flex: 1,
          width: '100%'
        },
        absolute: {
          position: 'absolute',
          top: 5,
          right: 5,
          color: '#fff'
        },
        flex: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }
      }),
    [paddingRight]
  );

  return (
    <TPressable
      aria-label={translate ? i18n.t(idString) : idString}
      bgColor={style.routeName === style.activeRoute ? 'backgroundActive' : 'transparent'}
      onPress={onPress}
      onHoverIn={() => onHoverIn()}
      onHoverOut={onHoverOut}
      border={noAvaliable ? 'secondary' : 'transparent'}
      style={styles.pressable}
    >
      {(absolute || collapsed) && (
        <TView
          bgColor="transparent"
          style={{ position: 'absolute', top: 3, right: 8, zIndex: 999 }}
        >
          {badge}
        </TView>
      )}
      <TView bgColor="transparent" style={styles.children1}>
        <TView bgColor="transparent" style={styles.leftComponent}>
          {svg ? (
            <MemoizedAvatarCard
              bgColor={bgAvatar ? 'transparent' : null}
              name={name}
              width="$6"
              picture={icon}
            />
          ) : (
            <MaterialCommunity size={w} icon={icon} color={`$${color}`} />
          )}
          <TText
            style={{
              display: collapsed ? 'none' : 'flex',
              textTransform: 'capitalize'
            }}
            color={noAvaliable ? 'secondary' : (color ?? null)}
            size="md"
            numberOfLines={1}
            lineBreakMode="clip"
          >
            {translate ? i18n.t(idString) : idString}
          </TText>
        </TView>

        {dosVertical && (
          <MenuDots
            display={display}
            openMenu={openMenu}
            onCloseMenu={onCloseMenu}
            onHoverInDots={onHoverInDots}
            onHoverOutDots={onHoverOutDots}
            setOpenMenu={setOpenMenu}
            color={color}
            id={id}
          />
        )}

        {!collapsed ? (
          <>
            {iconRight ?? iconRight}
            {!absolute && <TView bgColor="transparent">{badge}</TView>}
          </>
        ) : null}
      </TView>
    </TPressable>
  );
}

export default React.memo(ItemDrawerSettings, (prevProps, nextProps) => {
  return (
    prevProps.style.activeRoute === nextProps.style.activeRoute &&
    prevProps.style.routeName === nextProps.style.routeName &&
    prevProps.idString === nextProps.idString &&
    prevProps.icon === nextProps.icon &&
    prevProps.color === nextProps.color &&
    prevProps.bgAvatar === nextProps.bgAvatar &&
    prevProps.collapsed === nextProps.collapsed &&
    prevProps.dosVertical === nextProps.dosVertical &&
    prevProps.translate === nextProps.translate &&
    prevProps.svg === nextProps.svg &&
    prevProps.name === nextProps.name &&
    prevProps.paddingRight === nextProps.paddingRight &&
    prevProps.absolute === nextProps.absolute &&
    prevProps.direction === nextProps.direction &&
    prevProps.badge === nextProps.badge &&
    prevProps.iconRight === nextProps.iconRight &&
    prevProps.noAvaliable === nextProps.noAvaliable &&
    prevProps.color === nextProps.color &&
    prevProps.bgAvatar === nextProps.bgAvatar &&
    prevProps.id === nextProps.id
  );
});
