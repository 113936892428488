import React, { memo, useCallback, useMemo } from 'react';
import { Menu, MenuIcon, MenuItem, Pressable } from '@gluestack-ui/themed';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { ChevronDown, IconIdea } from '@/assets/icons/Icons';
import { MenuItemLabel } from '@gluestack-ui/themed';
import translate from '@/helpers/traslations';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { getContext } from '@/utils/functions';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { InConverStackParams } from '@/routers/interfaces';
import { StyleSheet } from 'react-native';
import { setDialogConnect, setMenuRigth } from '@/redux/slices/theme';
import { ContractsEnum } from '@/types/enums/conversation';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { TABLET_SCREEN } from '@/utils/globales';
import { InConverStackRoutes, Routes } from '@/routers/routes';
import { useCollapsed } from '@/providers/CollpasedProvider';

const ChevronMessage = ({ setDataBc, conversationId, messageId }) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<StackNavigationProp<InConverStackParams>>();
  const sections = useAppSelector((state: RootState) => state.messages.sections?.[conversationId]);
  const contracts = useAppSelector((state: RootState) => state.user.contracts);

  const { setCollapsed } = useCollapsed();

  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  const handlePressIdea = useCallback(() => {
    if (!contracts?.includes(ContractsEnum?.chat)) {
      dispatch(setDialogConnect(true));
      return;
    }
    const data = getContext(sections, messageId);
    data && setDataBc(data);

    if (isPc) {
      dispatch(setMenuRigth(true));
      setCollapsed(true);
      setTimeout(() => {
        navigation.navigate(InConverStackRoutes.Embedding);
      }, 100);
    } else {
      navigation.navigate(InConverStackRoutes.Embedding);
    }
  }, [sections, messageId, setDataBc, navigation, dispatch, setCollapsed, contracts]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        button: {
          width: 30,
          height: 30,
          borderRadius: 100,
          justifyContent: 'center',
          alignItems: 'center'
        }
      }),
    []
  );

  return (
    <Menu
      trigger={({ ...triggerProps }) => (
        <Pressable
          style={styles.button}
          sx={{
            ':hover': {
              backgroundColor: '$backgroundActive',
              cursor: 'pointer'
            }
          }}
          {...triggerProps}
        >
          <MaterialCommunity icon={ChevronDown} size="md" />
        </Pressable>
      )}
    >
      <MenuItem key={'embedding'} textValue="embedding" onPress={handlePressIdea}>
        <MenuIcon as={IconIdea} size="sm" />
        <MenuItemLabel ml="$2" size="sm">
          {translate('embedding')}
        </MenuItemLabel>
      </MenuItem>
    </Menu>
  );
};

export default memo(ChevronMessage);
