import React, { useCallback, useMemo } from 'react';
import {
  FileIcon,
  GifBox,
  Image,
  Location,
  MessageVideo,
  Microphone,
  Sticker
} from '@/assets/icons/Icons';
import LastMessage from './LastMessage';
import LastMessageIcon from './LasMesageIcon';
import i18n from '../providers/Localization';
import LastMessageInfo from './LastMessageInfo';
import { MessageRecivedRenderProps } from './interfaces';
import { TypeOfMessage } from '@/types/enums/message';
import translate from '@/helpers/traslations';
import { TText, THView } from '@/components/themed';
import { RootState, useAppSelector } from '@/redux/store';

function MessageRecivedRender({ messages, isGroup }: MessageRecivedRenderProps) {
  const conversationId = useMemo(() => messages?.id_conversation, [messages]);
  const unread = useAppSelector(
    (state: RootState) => state.conversations?.unread?.[conversationId] ?? 0
  );

  const renderMessage = useCallback(() => {
    switch (messages?.type) {
      case TypeOfMessage?.image:
        return (
          <LastMessageIcon
            color={unread === 0 ? 'secondary' : 'text'}
            icon={Image}
            message={i18n.t('Image')}
            italic={false}
          />
        );
      case TypeOfMessage?.sticker:
      case TypeOfMessage?.whatsappSticker:
        return (
          <LastMessageIcon
            color={unread === 0 ? 'secondary' : 'text'}
            icon={Sticker}
            message={i18n.t('Sticker')}
            italic={false}
          />
        );
      case TypeOfMessage?.info:
        return <LastMessageInfo message={messages} />;
      case TypeOfMessage?.gif:
        return (
          <LastMessageIcon
            color={unread === 0 ? 'secondary' : 'text'}
            icon={GifBox}
            message={i18n.t('Gif')}
            italic={false}
          />
        );
      case TypeOfMessage?.audio:
        return (
          <LastMessageIcon
            color={unread === 0 ? 'secondary' : 'text'}
            icon={Microphone}
            message={i18n.t('Audio')}
            italic={false}
          />
        );
      case TypeOfMessage?.file:
        return (
          <LastMessageIcon
            color={unread === 0 ? 'secondary' : 'text'}
            icon={FileIcon}
            message={messages?.content?.file?.name}
            italic={false}
          />
        );
      case TypeOfMessage?.video:
        return (
          <LastMessageIcon color="secondary" icon={MessageVideo} message="Video" italic={false} />
        );
      case TypeOfMessage?.template:
      case TypeOfMessage?.text:
        return <LastMessage message={messages} isGroup={isGroup} />;
      case TypeOfMessage?.location:
        return (
          <LastMessageIcon
            color={unread === 0 ? 'secondary' : 'text'}
            icon={Location}
            message={translate('location')}
            italic={false}
          />
        );
      default:
        return (
          <TText color="secondary" size="sm" style={{ fontStyle: 'italic' }}>
            {i18n.t('messageUnsuported')}
          </TText>
        );
    }
  }, [messages, unread, isGroup]);

  return (
    <THView style={{ alignItems: 'center', justifyContent: 'flex-start', gap: 4, width: '100%' }}>
      {renderMessage()}
    </THView>
  );
}

export default React.memo(MessageRecivedRender);
