import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import i18n from '@/providers/Localization';
import { Badge } from '@gluestack-ui/themed';
import { Check, CheckAll, ClockTime, InfoOutline } from '@/assets/icons/Icons';
import MessageRecivedRender from '../../../atoms/MessageRecivedRender';
import ContainerLastMessage from '../../../atoms/ContainerLastMessage';
import { RootState, useAppSelector } from '@/redux/store';
import { TText, TView } from '@/components/themed';

function LastMessageContainer({
  lastMessage,
  fragmentsStatus,
  isGroup = false
}: {
  lastMessage: any;
  fragmentsStatus: string;
  isGroup?: boolean;
}) {
  const conversationId = useMemo(() => lastMessage?.id_conversation, [lastMessage]);
  const userEmail = useAppSelector((state: RootState) => state.user.user?.email);

  const unread = useAppSelector(
    (state: RootState) => state.conversations?.unread?.[conversationId] ?? 0
  );

  const styles = useMemo(
    () =>
      StyleSheet.create({
        containertextIcon: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          flex: 1
        }
      }),
    []
  );

  return (
    <TView style={styles.containertextIcon}>
      {lastMessage && Object.keys(lastMessage)?.length ? (
        lastMessage?.from?.email == userEmail ? (
          <TView>
            {lastMessage?.status === 'pending' && (
              <ContainerLastMessage messages={lastMessage} icon={ClockTime} color="$info" />
            )}
            {lastMessage?.status === 'delivered' && (
              <ContainerLastMessage messages={lastMessage} icon={CheckAll} color="$iconos" />
            )}
            {lastMessage?.status === 'error' && (
              <ContainerLastMessage messages={lastMessage} icon={InfoOutline} color="$error" />
            )}
            {lastMessage?.status === 'open' && (
              <ContainerLastMessage messages={lastMessage} icon={CheckAll} color="$iconos" />
            )}
            {lastMessage?.status === 'read' && (
              <ContainerLastMessage messages={lastMessage} icon={CheckAll} color="$checked" />
            )}
            {lastMessage?.status === 'send' && (
              <ContainerLastMessage messages={lastMessage} icon={Check} color="$iconos" />
            )}
          </TView>
        ) : (
          <MessageRecivedRender isGroup={isGroup} messages={lastMessage} />
        )
      ) : (
        <TText
          style={{
            fontStyle: 'italic'
          }}
          color="secondary"
        >
          {i18n.t('noMessages')}
        </TText>
      )}

      {unread > 0 && (
        <TView>
          <Badge size="sm" />
        </TView>
      )}
      {fragmentsStatus === 'pending' && (
        <TView>
          <Badge
            style={{
              backgroundColor: '$pending'
            }}
            size="sm"
          />
        </TView>
      )}
    </TView>
  );
}

export default React.memo(LastMessageContainer);
