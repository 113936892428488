import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setThemeMode, setVersion, setViewSliderVersion } from '../../actionTypes';

import { ThemeMode, Themes } from '../../../types/theme';
import { ActiveDialog } from '@/components/sidebar/sidebarFilters/interface';

export interface ThemeState {
  themeMode: ThemeMode;
  newVersion: boolean;
  conversation: {
    [conversationId: string]: {
      inputValue?: string;
      inputHeight?: number;
      selection?: { start: number; end: number };
      textToSearchDinamic?: string;
      fileAttachment?: {
        file: Blob;
        name: string;
      };
    };
  };
  lastInputKeyPress: string;
  modalDinamic: boolean;
  modalMore: boolean;
  menuRigth: boolean;
  viewSliderVersion: boolean;
  selectedChannelToInitConversation: string[];
  popoverChannel: boolean;
  dialogConnect: boolean;
  limitReached: boolean;
  popoverCloseInConverStack: boolean;
  dialogChannels: {
    status: boolean;
    data: ActiveDialog;
  };
  itemDisabled: {
    [key: string]: boolean;
  };
}

const initialState: ThemeState = {
  themeMode: Themes?.DARK,
  newVersion: false,
  conversation: {},
  lastInputKeyPress: '',
  modalDinamic: false,
  modalMore: false,
  menuRigth: false,
  dialogConnect: false,
  viewSliderVersion: true,
  selectedChannelToInitConversation: [],
  popoverChannel: false,
  limitReached: false,
  popoverCloseInConverStack: false,
  dialogChannels: {
    status: false,
    data: {} as ActiveDialog
  },
  itemDisabled: {}
};

const themeSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setConversationInputValue(state, action) {
      // que no guarde mas de 50 conversaciones en el estado
      // esto es para que no tenga un impacto en la memoria
      if (state.conversation && Object.keys(state.conversation)?.length > 10) {
        const keys = Object.keys(state.conversation)?.slice(0, 5);
        keys.forEach((key) => {
          delete state.conversation[key];
        });
      }
      state.conversation[action.payload.conversationId] = {
        ...state.conversation[action.payload.conversationId],
        inputValue: action.payload.inputValue
      };
    },
    setConversationInputHeight(state, action) {
      const stateInputHeight = state.conversation[action.payload.conversationId]?.inputHeight;
      if (stateInputHeight === action.payload.inputHeight) {
        return;
      }
      state.conversation[action.payload.conversationId] = {
        ...state.conversation[action.payload.conversationId],
        inputHeight: action.payload.inputHeight
      };
    },
    setLastInputKeyPress(state, action) {
      state.lastInputKeyPress = action.payload;
    },
    setMenuDinamic(state, action) {
      state.modalDinamic = action.payload;
    },
    setModalMore(state, action) {
      state.modalMore = action.payload;
    },
    setSelections(state, action) {
      //que no se guarde mas de 10 conversaciones en el estado
      if (state.conversation && Object.keys(state.conversation).length > 10) {
        const keys = Object.keys(state.conversation)?.slice(0, 5);
        keys.forEach((key) => {
          delete state.conversation[key];
        });
      }
      state.conversation[action.payload.conversationId] = {
        ...state.conversation[action.payload.conversationId],
        selection: action.payload.selection
      };
    },
    setTextToSearchDinamic(state, action) {
      if (
        action.payload.textToSearchDinamic ===
          state.conversation?.[action.payload.conversationId] ||
        action.payload.textToSearchDinamic?.trim()?.length === 0
      ) {
        return;
      } else {
        // que se guarde como maximo 10 conversaciones en el estado
        if (state.conversation && Object.keys(state.conversation).length > 10) {
          const keys = Object.keys(state.conversation)?.slice(0, 5);
          keys.forEach((key) => {
            delete state.conversation[key];
          });
        }
        state.conversation[action.payload.conversationId] = {
          ...state.conversation[action.payload.conversationId],
          textToSearchDinamic: action.payload.textToSearchDinamic
        };
      }
    },
    setSelectedChannelToInitConversation(state, action) {
      state.selectedChannelToInitConversation = action.payload;
      state.popoverChannel = false;
    },
    setMenuRigth(state, action) {
      state.menuRigth = action.payload;
    },
    setFileAttachment(state, action) {
      state.conversation[action.payload.conversationId] = {
        ...state.conversation[action.payload.conversationId],
        fileAttachment: action.payload.fileAttachment
      };
    },
    setDialogConnect(state, action) {
      state.dialogConnect = action.payload;
    },
    setLimitReached(state, action) {
      state.limitReached = action.payload;
    },
    setPopoverCloseInConverStack(state, action) {
      state.popoverCloseInConverStack = action.payload;
    },
    setDialogChannels(state, action) {
      state.dialogChannels = action.payload;
    },
    setItemDisabled(state, action: PayloadAction<{ key: string; value: boolean }>) {
      state.itemDisabled = {
        ...state.itemDisabled,
        [action.payload.key]: action.payload.value
      };
    },
    setPopoverChannel(state, action) {
      state.popoverChannel = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setThemeMode, (state, action) => {
      state.themeMode = action.payload as Themes;
    });
    builder.addCase(setVersion, (state, action) => {
      state.newVersion = action.payload;
    });
    builder.addCase(setViewSliderVersion, (state, action) => {
      state.viewSliderVersion = action.payload;
    });
  }
});

export const {
  setConversationInputValue,
  setConversationInputHeight,
  setLastInputKeyPress,
  setMenuDinamic,
  setModalMore,
  setSelections,
  setTextToSearchDinamic,
  setSelectedChannelToInitConversation,
  setMenuRigth,
  setFileAttachment,
  setDialogConnect,
  setLimitReached,
  setPopoverCloseInConverStack,
  setDialogChannels,
  setItemDisabled,
  setPopoverChannel
} = themeSlice.actions;

export default themeSlice.reducer;
