import { Dimensions, Linking, Platform, StyleProp, ViewStyle } from 'react-native';

import * as Clipboard from 'expo-clipboard';
import * as FileSystem from 'expo-file-system';
import Buffer from 'buffer';
import { toUint8Array } from 'js-base64';
import moment from 'moment';
import {
  AlertCircle,
  AlertTriangle,
  Check,
  CheckAll,
  CheckCircle,
  ClockTime,
  CloseCircle,
  Hammer,
  IconBug,
  IconFilterClosed,
  InfoOutline,
  LockOpen,
  MinusCircle,
  NewBox,
  ShieldLock,
  SyncCircle
} from '@/assets/icons/Icons';
import i18n from '../providers/Localization';
import { Filters } from '@/redux/slices/filters/interfaces';
import { Status, TypesSections } from '../types';
import { filterToCountSidebar } from './filters';
import { MOBILE_SCREEN } from './globales';
import { InstanceType } from '@/components/conversation/body/FragmentComponent/interfaces';
import { ActiveDialog } from '@/components/sidebar/sidebarFilters/interface';
import { SectionInterface } from '@/redux/slices/messages/interfaces';
import { Label } from '@/redux/slices/labels/interfaces';
import { Participant, TypeUserParticipant } from '@/redux/slices/participants/interfaces';
import { Contacts } from '@/types/typesRedux/interfaces';
import { EMOJI_REPLACEMENT } from './constants';
import { TypeSections } from '@/types/enums';

export function handleKeyDown(event, callback): boolean {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault(); // Evita el salto de línea predeterminado
    callback(event); // Envía el mensaje
    return true;
  }
}

export const getImageToClipboard = async () => {
  const content = await Clipboard.getImageAsync({ format: 'jpeg' });
  let uri;
  let file;

  if (Platform.OS === 'web') {
    file = await base64ToBlob(content.data);
    uri = content.data;
  } else {
    // Strip off the data:image/png;base64, part
    const base64 = content.data.replace(/^data:image\/[a-z]+;base64,/, '');

    // Generate a file URI in the device's cache directory
    uri = `${FileSystem.cacheDirectory + new Date().getTime()}.jpg`;

    // Write the Base64 string to a local file
    await FileSystem.writeAsStringAsync(uri, base64, { encoding: 'base64' });
  }
  return { uri, file: file || uri, mimeType: 'image/jpeg', temp: true };
};

export async function base64ToBlob(base64) {
  if (Platform.OS === 'web') {
    const contentType = base64.split(',')[0].split(':')[1].split(';')[0];

    const stringBase = base64.split(',')[1];
    const byteCharacters = Buffer.Buffer.from(stringBase, 'base64');

    return new Blob([byteCharacters], { type: contentType });
  }
  const byteCharacters = Buffer.Buffer.from(base64.base64Data, 'base64');

  return new Blob([byteCharacters], { type: base64.type });
}

export async function blobToTempFile(base64Image) {
  const uint8Array = toUint8Array(base64Image.base64Data);
  return new Blob([uint8Array], { type: base64Image.type });
}

export const closeAudio = (callback) => {
  callback(undefined);
};

export const copyToClipboard = async (text: string) => {
  try {
    await Clipboard.setStringAsync(text);
    return true;
  } catch (error) {
    console.log('error', error);
    return false;
  }
};

export const copyImgClipboard = async (uri: string) => {
  // trnasformar la uri en un archivo base64

  await Clipboard.setImageAsync(uri);
};

export const getBackgroundImage = (themeMode) => {
  if (Platform.OS === 'web') {
    return themeMode === 'dark'
      ? require('../assets/images/back_destktop.svg')
      : require('../assets/images/light_desktop.svg');
  }
  return themeMode === 'dark'
    ? require('../assets/images/mobile.svg')
    : require('../assets/images/mobile_light.svg');
};

export const openImageModal = (callback) => {
  callback(true);
};

export const closeImageModal = (callback) => {
  callback(false);
};

export const isArray = (value) => {
  return Array.isArray(value);
};
interface SearchInput {
  event: string;
  arrayToFilter: any;
  arrayFilterNoPagination: any;
  callbackSetFilter: React.Dispatch<React.SetStateAction<any>>;
  callbackOnChangeSearch: React.Dispatch<React.SetStateAction<any>>;
  inputSearch: string;
}
export const handleSearchInInput = ({
  event,
  arrayToFilter,
  arrayFilterNoPagination,
  callbackSetFilter,
  callbackOnChangeSearch,
  inputSearch,
  searchProperties = ['email', 'name'] // un array de cadenas que contiene las propiedades por las cuales filtrar
}) => {
  /*
   * Recibe un evento de teclado, un array de objetos para filtrar y un array de propiedades
   * para buscar dentro de los objetos.
   * Si el evento está vacío, devuelve el array sin filtrar.
   * Si el evento no está vacío, devuelve el array filtrado por las propiedades dadas.
   */

  callbackOnChangeSearch(event);

  if (event?.length === 0) {
    callbackSetFilter(arrayToFilter);
  } else {
    const filtro = arrayFilterNoPagination?.filter((item) =>
      searchProperties.some((property) =>
        item[property]?.toString().toLowerCase().includes(inputSearch?.toLowerCase())
      )
    );
    callbackSetFilter(filtro);
  }
};

export const verifyIsMobile = (): boolean => {
  /*
   * Comprueba si la aplicación se está ejecutando en un dispositivo móvil
   */
  const windowWidth = Dimensions.get('window').width;
  const isMobile = Platform.OS === 'web' && windowWidth < MOBILE_SCREEN;
  return isMobile;
};

export const IS_WEB_PC = Platform.OS === 'web' && !verifyIsMobile();

/**
 * Determines if the current device is not a native mobile device.
 *
 * This function checks if the platform is 'web' and also verifies if it is not mobile.
 *
 * @returns {boolean} Returns `true` if the platform is web and not mobile, otherwise returns `false`.
 */
export const verifyIsNotNativeDevice = () => {
  if (IS_WEB_PC) {
    return true;
  }
  return false;
};
export function areArraysEqual(arr1: number[] | string[], arr2: number[] | string[]) {
  /*
   * Recibe dos arrays de objetos y comprueba si son iguales
   */

  // Comprueba si ambos arrays tienen la misma longitud
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  // Crea conjuntos a partir de los IDUsuarios de ambos arrays
  const set1 = new Set(arr1?.map((user) => user));
  const set2 = new Set(arr2?.map((user) => user));

  // Comprueba si ambos conjuntos tienen la misma longitud
  if (set1?.size !== set2?.size) {
    return false;
  }

  // Comprueba si todos los elementos de set1 están en set2
  for (const id of set1) {
    if (!set2?.has(id)) {
      return false;
    }
  }

  // Si llegamos hasta aquí, entonces los arrays contienen los mismos usuarios
  return true;
}

export function tieneMensajesNoLeidos(id_conversation, state) {
  /*
   *Recibe el id_conversation y el estado de la conversación y retorna true si tiene mensajes no leídos
   */
  // Iteramos sobre todas las llaves del estado, es decir, las categorías principales como 'chat', 'clients', etc.
  for (const categoryKey in state) {
    // Dentro de cada categoría, iteramos sobre los sub-categorías como 'todos', 'grupos', 'cerrados', etc.
    for (const subcategoryKey in state[categoryKey]) {
      // Verificamos si el id_conversation está presente en la lista 'unread' y si tiene un valor mayor a 0.
      if (state[categoryKey][subcategoryKey]?.unread[id_conversation] > 0) {
        return true;
      }
    }
  }
  return false;
}

export const getIconsContact = (type: string) => {
  /*
   *recibe el tipo de contacto y retorna el icono correspondiente
   */
  switch (type) {
    case 'company':
      return 'office-building';
    case 'person':
      return 'account';
    default:
      return 'user';
  }
};

export const verifyAssisneee = (
  fragment: { [keyString: string]: boolean }[],
  keyString: string
) => {
  /*
   *recibe el arreglo de associations del fragmento y el string de la key que se quiere buscar
   * retorna true si encuentra el string y el value es true en el arreglo
   * retorna false si no lo encuentra o el value es false
   */

  // Check if fragment is not an array or is null/undefined
  if (!Array.isArray(fragment) || !fragment || fragment.length === 0) {
    return false;
  }
  for (const item of fragment) {
    if (item.hasOwnProperty(keyString) && item[keyString]) {
      return true;
    }
  }
  return false;
};

export function dateYearFormat(date: string | Date) {
  if (date) {
    const fecha = moment.utc(date);
    // Formatea la fecha al formato deseado
    const fechaFormateada = fecha.format('DD/MM/YYYY');

    return fechaFormateada;
  }
  return i18n.t('selectDate');
}

export const formatToLocalTime = (utcTime) => {
  return moment.utc(utcTime, 'HH:mm').local().format('HH:mm');
};

export const getIcons = (type: Status) => {
  switch (type) {
    case 'info':
      return InfoOutline;
    case 'success':
      return CheckCircle;
    case 'warning':
      return AlertTriangle;
    case 'error':
      return CloseCircle;
    case 'open':
      return LockOpen;
    case 'closed':
      return IconFilterClosed;
    case 'expired':
      return ClockTime;
    case 'bug':
      return IconBug;
    case 'added':
      return NewBox;
    case 'changed':
      return SyncCircle;
    case 'removed':
      return MinusCircle;
    case 'deprecated':
      return AlertCircle;
    case 'fixed':
      return Hammer;
    case 'security':
      return ShieldLock;
    case 'pending':
      return ClockTime;
    case 'delivered':
    case 'read':
      return CheckAll;
    case 'send':
      return Check;
    case 'new':
      return NewBox;
    case 'failed':
      return AlertCircle;
    case 'rejected':
      return CloseCircle;
    default:
      return InfoOutline;
  }
};

export function getColor(status) {
  switch (status) {
    case 'pending':
      return '$info';
    case 'delivered':
    case 'open':
    case 'send':
      return '$iconos';
    case 'error':
      return '$error';
    case 'read':
      return '$checked';
    case 'rejected':
      return '$error';
    case 'failed':
      return '$error';
    default:
      return ''; // Color por defecto
  }
}

export function getColorByType(
  type: 'added' | 'changed' | 'deprecated' | 'removed' | 'fixed' | 'security'
): string {
  switch (type) {
    case 'added':
      return '#4CAF50';
    case 'changed':
      return '#2196F3';
    case 'deprecated':
      return '#FFC107';
    case 'removed':
      return '#F44336';
    case 'fixed':
      return '#9C27B0';
    case 'security':
      return '#607D8B';
    default:
      return '#FFFFFF'; // Color por defecto (puedes cambiarlo si lo deseas)
  }
}

// Función para filtrar y retornar los objetos con types='clients'
export const filterByClients = (array: Filters[], type: TypesSections): Filters[] => {
  return array?.filter((item) => item?.types === type);
};

// Función para recorrer todas las keys del objeto y obtener los filtros correspondientes
export const getClientsFilters = (filtersObject, type) => {
  return Object.values(filtersObject)?.reduce<Filters[]>((acc: any, currentArray: Filters[]) => {
    return acc?.concat(filterByClients(currentArray, type));
  }, []);
};

/** doc
 * @param {string} sectionName
 * @param {object} filterConversations
 * @returns {number}
 */
export function getFilterWithOpenConversations({
  sectionName,
  filterConversations
}: {
  sectionName: TypeSections;
  filterConversations: object;
}): number {
  let filterCount = 0;
  if (filterConversations[sectionName]) {
    const section = filterConversations[sectionName];
    for (const filterKey in section) {
      if (filterToCountSidebar.includes(filterKey)) {
        filterCount += section[filterKey]?.conversationCountWithOpenFragment ?? 0;
      }
    }
  }
  return filterCount;
}

export const getCurrentFilterByState = (state, routeName: string) => {
  let filterToReturn: Filters;
  const keysFilter = Object.keys(state);
  keysFilter?.forEach((key: string) => {
    state[key]?.forEach((filter: Filters) => {
      if (filter?.idString === routeName) {
        filterToReturn = filter;
      }
    });
  });
  return filterToReturn;
};

/** doc
 * @param {string} sectionName
 * @param {object} filterConversations
 * @returns {boolean}
 */

function hasUnreadInFilter({ filter, unread }): boolean {
  const conversations = filter?.conversations;
  if (filter && conversations) {
    return conversations.some((conversation) => unread?.[conversation?.id] > 0);
  }
  return false;
}

export function hasUnreadMessages({
  sectionName,
  filterConversations,
  unread
}: {
  sectionName: string;
  filterConversations: object;
  unread: { [key: string]: number };
}): boolean {
  const section = filterConversations?.[sectionName];

  if (section) {
    return filterToCountSidebar.some((filterKey) =>
      hasUnreadInFilter({ filter: section?.[filterKey], unread })
    );
  }
  return false;
}

export const getUnreadMessagesByFilter = ({
  filterName,
  filterConversations,
  routeName,
  unread
}: {
  filterName: string;
  routeName: string;
  filterConversations: object;
  unread: { [key: string]: number };
}): boolean => {
  const conversations = filterConversations?.[routeName]?.[filterName]?.conversations;

  if (conversations) {
    for (const conversation of conversations) {
      if (unread[conversation.id] > 0) {
        return true;
      }
    }
  }

  return false; // Asegúrate de devolver un valor predeterminado si no se cumplen las condiciones anteriores
};

/**
 *
 * @param filterName string (nombre del filtro)
 * @returns number (cantidad de conversaciones que tienen mensajes no leidos)
 */
export const getCantConversOpenByFilter = ({
  filterName,
  routeName,
  filterConversations
}: {
  filterName: string;
  routeName: string;
  filterConversations: object;
}): number => {
  if (filterConversations && filterConversations[routeName]) {
    if (filterConversations[routeName][filterName]) {
      return filterConversations[routeName][filterName]?.conversationCountWithOpenFragment;
    }
  }
  return 0; // Asegúrate de devolver un valor predeterminado si no se cumplen las condiciones anteriores
};

export function findConversationFilter(conversationId, state) {
  console.log('conversationId', { conversationId, state });
  //validar que el estado no sea null
  if (!state) {
    return null;
  }
  // Iterar a través de las llaves principales: 'clients' y 'chat'
  for (const mainKey in state) {
    // Iterar a través de cada filtro dentro de las llaves principales
    for (const filterKey in state[mainKey]) {
      console.log('filterKey', { filterKey, state: state[mainKey] });

      // Encontrar el índice de la conversación en el arreglo actual
      const conversationIndex = state[mainKey][filterKey].conversations.findIndex(
        (conversation) => conversation.id === conversationId
      );
      // Si se encuentra la conversación, retornar la llave principal y la llave del filtro
      if (conversationIndex !== -1) {
        return { mainKey, filterKey };
      }
    }
  }

  // Si la conversación no se encuentra en ningún filtro, retornar null
  return null;
}

export function findNextElement(arr, id) {
  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i] === id) {
      return arr[i + 1];
    }
  }
  return null; // Retorna null si el id no se encuentra o es el último elemento
}

export const getLastMessage = (sections) => {
  if (sections && Array.isArray(sections) && sections?.length > 0) {
    if (sections && sections?.length > 1) {
      return sections[1]?.data;
    }
  }
  return null;
};

export const findFragmentByMessageId = ({ array, id }) => {
  // Encuentra el índice del mensaje
  if (!id) {
    return [[], array, []];
  }
  const messageIndex = array.findIndex((item) => item.id === id && item.type === 'message');
  if (messageIndex === -1) return [[], [], []]; // Si el mensaje no se encuentra

  // Encuentra los índices del header y footer del fragmento
  let startIndex = messageIndex;
  while (startIndex > 0 && array[startIndex].type !== 'footer') {
    startIndex--;
  }

  let endIndex = messageIndex;
  while (endIndex < array.length && array[endIndex].type !== 'header') {
    endIndex++;
  }

  // Divide el arreglo en tres partes
  const previousMessages = array.slice(0, startIndex);
  const currentFragment = array.slice(startIndex, endIndex + 1);
  const nextMessages = array.slice(endIndex + 1);

  return [previousMessages, currentFragment, nextMessages];
};

export function findIndexById(
  array: { id: string; type: string; data: any }[],
  id: string
): number {
  return array?.findIndex((element) => element?.id === id);
}

export const getDisplayName = (contact) => {
  const { name, last_name } = contact;

  if (name && last_name) {
    // Si tanto el nombre como el apellido existen
    return `${name} ${last_name}`;
  }
  if (name) {
    // Solo el nombre existe
    return name;
  }
  if (last_name) {
    // Solo el apellido existe
    return last_name;
  }
  // Ni el nombre ni el apellido existen
  return 'Sin nombre';
};

export const openURL = async (url) => {
  const canOpen = await Linking.canOpenURL(url);

  if (canOpen) {
    Linking.openURL(url);
  } else {
    console.log('No se puede abrir la URL');
  }
};

export const cleanPhoneNumber = (phoneNumber: string | number): string => {
  // Comprobar si phoneNumber es una cadena no vacía
  if (typeof phoneNumber === 'string' && phoneNumber?.trim() !== '') {
    // Eliminar espacios y el signo '+'
    return phoneNumber?.replace(/\s+/g, '')?.replace('+', '').replace('-', '');
  }
  if (typeof phoneNumber === 'number') {
    // Si es un número, convertirlo a cadena
    return phoneNumber?.toString();
  }
  // Si no es una cadena o es una cadena vacía, devolver un valor predeterminado
  return '';
};

/**
 *
 * @param routeName : string
 * @returns Object {StyleProp<ViewStyle>}
 */
export const getItemStyle = ({
  routeName,
  activeRoute
}: {
  routeName: string;
  activeRoute: string;
}): StyleProp<ViewStyle> => {
  console.log('routeName', routeName, activeRoute);

  return {
    backgroundColor:
      routeName === activeRoute ? '$backgroundActiveNavigationPxsol' : '$transparent',
    borderRadius: 10,
    width: '100%'
  };
};

export type ActionBadge = 'info' | 'success' | 'error' | 'warning' | 'muted';

export const getActionBadgeByStatus = (instance: InstanceType) => {
  let action: ActionBadge;
  switch (instance) {
    case 'confirmadas':
      action = 'success';
      break;
    case 'noventa':
      action = 'error';
      break;
    case 'pending':
      action = 'warning';
      break;
    case 'consulta':
      action = 'info';
      break;
    case 'postVenta':
      action = 'success';
      break;
    default:
      action = 'muted';
      break;
  }
  return action;
};

export const getActionBadgeStatus = (status: Status) => {
  let action: ActionBadge;
  switch (status) {
    case 'open':
      action = 'success';
      break;
    case 'new':
      action = 'info';
      break;
    case 'pending':
      action = 'warning';
      break;
    case 'error':
      action = 'error';
      break;
    case 'closed':
      action = 'muted';
      break;
    default:
      action = 'error';
      break;
  }
  return action;
};

export function getDialogText(dialog: ActiveDialog) {
  if (dialog?.available) {
    return 'actions.addChannel';
  } else {
    return 'actions.continue';
  }
}

export function formatNumber(num: number) {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(num % 1000 !== 0 ? 1 : 0) + 'K';
  } else {
    return (num / 1000000).toFixed(num % 1000000 !== 0 ? 1 : 0) + 'M';
  }
}

export const getContext = (
  data: SectionInterface[],
  selectedMessageId: string
): { question: string; answer: string } => {
  const selectedMessage = data?.find(
    (section: SectionInterface) => section?.id === selectedMessageId
  );
  const fragmentId = selectedMessage?.data?.id_fragment;
  const messagesInFragment = data?.filter(
    (section: SectionInterface) =>
      section?.data?.id_fragment === fragmentId &&
      (section?.data?.type === 'text' || section?.data?.type === 'template')
  );
  let answer = '';
  let question = '';
  const selectedIndex = messagesInFragment.findIndex((m) => m.id === selectedMessageId);

  if (selectedMessage?.data?.origin === 'internal') {
    answer = selectedMessage.data.content.text;
    for (let i = selectedIndex + 1; i <= messagesInFragment?.length - 1; i++) {
      if (messagesInFragment[i]?.data?.origin === 'internal') {
        // question = messagesInFragment[i]?.data?.content?.text;
        if (messagesInFragment[i + 1]?.data?.origin === 'internal') {
          break;
        }
      } else {
        question = messagesInFragment[i].data.content.text + ' ' + question;
        if (messagesInFragment[i + 1]?.data?.origin === 'internal') {
          break;
        }
      }
    }
  } else {
    question = selectedMessage.data.content.text;
    //recorre para arriba del mensaje que se selecciono
    for (let i = selectedIndex + 1; i <= messagesInFragment?.length - 1; i++) {
      if (messagesInFragment[i]?.data?.origin !== 'internal') {
        question = `${messagesInFragment[i]?.data?.content?.text} ${question}`;
      } else {
        break; // Sale del bucle al encontrar otro mensaje saliente.
      }
    }
    for (let i = selectedIndex - 1; i >= 0; i--) {
      if (messagesInFragment[i]?.data?.origin === 'internal') {
        answer += messagesInFragment[i].data.content.text + ' ';
        break;
      } else {
        question = question + ' ' + messagesInFragment[i].data.content.text;
      }
    }
  }
  return { question: question.trim(), answer: answer.trim() };
};

export const isSelectedLabelsEqual = (selected, toFind) => {
  const selectedSet = new Set(selected);
  const toFindSet = new Set(toFind);
  if (selectedSet.size !== toFindSet.size) {
    return false;
  }
  for (let id of toFindSet) {
    if (!selectedSet.has(id)) {
      return false;
    }
  }
  return true;
};

export const isLessThan24Hours = ({ createdAt }) => {
  const pastDate: Date = new Date(createdAt);
  const currentDate: Date = new Date();

  const differenceInMilliseconds = currentDate.getTime() - pastDate.getTime();

  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  return differenceInDays >= 1;
};

export const getLabelbyId = (labels: Label[], id: string) => {
  return labels?.find((label) => label?.id?.toString() === id?.toString());
};
export const getUserIdMongoTo = ({
  participants,
  user,
  isInternal = false
}: {
  participants: Participant[];
  user: { id?: string; idMongoUser?: string };
  isInternal?: boolean;
}): TypeUserParticipant | undefined => {
  let userIdMongo: TypeUserParticipant | undefined = null;

  participants?.forEach((participant: Participant) => {
    const participantUser = participant?.userId;
    const participantId = isInternal ? participantUser?.id_user : participantUser?._id;
    const userId = isInternal ? user?.id : user?.idMongoUser;

    if (participantId?.toString() !== userId?.toString()) {
      if (!userIdMongo && participantUser) {
        userIdMongo = participantUser;
      }
    }
  });

  return userIdMongo;
};

export const getContactInfo = ({
  participants,
  user,
  isInternal = false
}: {
  participants: Participant[];
  user: { id?: string; idMongoUser?: string };
  isInternal?: boolean;
}): string[] | undefined => {
  let contactInfo: string[] | undefined;

  participants?.forEach((participant: Participant) => {
    const participantUser = participant?.userId;
    const participantId = isInternal ? participantUser?.id_user : participantUser?._id;
    const userId = isInternal ? user?.id : user?.idMongoUser;

    if (participantId?.toString() !== userId?.toString()) {
      if (!contactInfo) {
        contactInfo = participantUser?.name
          ? [participantUser.name]
          : participantUser?.phone
            ? [participantUser.phone]
            : contactInfo;
      }
    }
  });

  return contactInfo;
};

export function deepEqual(obj1, obj2) {
  // Si ambos son el mismo objeto
  if (obj1 === obj2) return true;

  // Si uno de ellos no es un objeto o es null
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  // Obtener las claves de ambos objetos
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Si tienen diferente número de claves
  if (keys1.length !== keys2.length) return false;

  // Verificar cada clave y su valor recursivamente
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (char) {
    var random = (Math.random() * 16) | 0;
    var value = char === 'x' ? random : (random & 0x3) | 0x8;
    return value.toString(16);
  });
}
