import React, { memo } from 'react';
import { TView, TVView } from '../themed';
import CustomSkeleton from './CustomSckeleton';
import SkeletonContactCard from './SkeletonContactCard';
import { generateUUID } from '@/utils/functions';
const skeleton = 15;

const SkeletonNewConver = () => {
  return (
    <TVView
      style={{
        flex: 1
      }}
    >
      <TView
        style={{
          paddingHorizontal: 8
        }}
      >
        <CustomSkeleton type="rectangle" height={55} width="100%" background="backgroundActive" />
      </TView>
      {Array(skeleton)
        .fill(0)
        .map((item, index) => (
          <SkeletonContactCard key={item + generateUUID() + index} />
        ))}
      <SkeletonContactCard />
    </TVView>
  );
};

export default memo(SkeletonNewConver);
