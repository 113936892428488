import React, { memo } from 'react';
import { ScrollViewProps, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import Animated, { AnimateProps, SharedValue, useAnimatedRef } from 'react-native-reanimated';

import { TView, ThemedViewProps } from './TView';
import { colorsProps, useThemeColor } from '@/hooks/useTheme';

type ThemedScrollViewProps = ThemedViewProps &
  AnimateProps<ScrollViewProps> & {
    horizontal?: boolean;
    style?: StyleProp<ViewStyle>;
    showsVerticalScrollIndicator?: boolean | SharedValue<boolean>;
    bgColor?: colorsProps;
    border?: colorsProps;
  };

export function ThemedScrollView({
  children,
  horizontal = false,
  style,
  showsVerticalScrollIndicator,
  lightColor,
  darkColor,
  bgColor,
  border,
  ...props
}: ThemedScrollViewProps) {
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, bgColor);
  const borderColor = useThemeColor({ light: lightColor, dark: darkColor }, border);
  const scrollRef = useAnimatedRef<Animated.ScrollView>();

  return (
    <TView style={[styles.container, style, { backgroundColor, borderColor }]}>
      <Animated.ScrollView
        ref={scrollRef}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        scrollEventThrottle={16}
        horizontal={horizontal} // Establece el scroll horizontal si se especifica
        {...props}
      >
        {children}
      </Animated.ScrollView>
    </TView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});

export default memo(ThemedScrollView);
