import React, { memo, useEffect, useState } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { filtersBase } from '@/utils/filters';
import CustomTabBar from '@/components/tabs/CustomTabBar';
import { Filters } from '@/redux/slices/filters/interfaces';
import colors from '../../../config/colors';
import { RootState, useAppSelector } from '@/redux/store';
import LayoutListConversation from '../LayoutListConversation';
import { getClientsFilters } from '@/utils/functions';

const Tab = createBottomTabNavigator();

const ChatStackMobile = (routeChat) => {
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const [filterByName, setFiltersByName] = useState<Filters[]>([]);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    if (routeChat?.route?.name) {
      const filterToGetChat = {
        assignee: filters?.assignee,
        labels: filters?.labels
      };
      const result = getClientsFilters(filterToGetChat, routeChat?.route?.name);

      setFiltersByName(result);
    }
  }, [filters, routeChat?.route?.name]);

  return (
    <Tab.Navigator
      tabBar={(props) => (
        <CustomTabBar
          {...props}
          filterByName={filterByName}
          viewMore={viewMore}
          setViewMore={setViewMore}
          routeName={routeChat?.route?.name}
          team={routeChat?.route?.params?.team}
        />
      )}
      screenOptions={{
        headerShown: false,
        tabBarActiveBackgroundColor: colors[themeMode]?.backgroundSecondary
      }}
    >
      {filtersBase?.map((filter) => {
        if (filter?.types?.includes(routeChat?.route?.name) && filter?.idString !== 'searchs') {
          return (
            <Tab.Screen
              key={filter?.id}
              name={filter?.name?.toLowerCase()}
              component={LayoutListConversation}
              initialParams={{ filter: filter?.idString, team: routeChat?.route?.params?.team }}
              options={{
                tabBarIcon: () => null,
                tabBarLabel: filter?.name,
                tabBarBadge: 10,
                tabBarBadgeStyle: {
                  position: 'absolute',
                  top: -5,
                  right: -20
                },
                tabBarStyle: {
                  flex: 1
                }
              }}
            />
          );
        }
        return null;
      })}
    </Tab.Navigator>
  );
};

export default memo(ChatStackMobile, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
