const tintColorLight = '#2f95dc';
const tintColorDark = '#eb10102e';
import { config as defaultConfig } from '@gluestack-ui/config';

export default {
  light: {
    ...defaultConfig.tokens.colors,
    text: '#3a3a3a',
    tint: tintColorLight,
    textTint: '#ff8a8a',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    border: '#dfdfdf',
    primary: '#cc3030',
    transparent: 'transparent',
    secondary: '#3a3a3a',
    success: '#00e676',
    background: '#eaeaea',
    background2: '#FFFFFF',
    backgroundNavigationPxsol: '#0f1621',
    backgroundSecondary: '#FFFFFF',
    backgroundBottomCustom: '#FFFFFF',
    backgroundSecondaryNavigationPxsol: '#fcfcfc',
    backgroundActive: '#7f7f7f33',
    backgroundActiveNavigationPxsol: '#17171733',
    backgroundExpired: '#D2F4EA',
    backgroundOpacity: '#dddcdc63',
    backgroundIconWpp: '#25D366',
    header: '#25232A',
    badgeSecondary: '#a0a0b0',
    pxsol: '#cc3030',
    pxsol500: '#6c1b1b',
    pxsol600: '#402121',
    pxsolOpacity: '#cc303020',
    blanco: '#FFFFFF',
    negro: '#000000',
    gris: '#A6ABAF',
    links: '#5baef0',
    iconos: '#333333',
    info: '#05457e',
    infoDarken: '#05457e',
    infoOpacity: '#0099b8db',
    warning: '#fed7aa',
    warningDarken: '#fb9c00',
    rigthMessage: '#006b62e0',
    rightMessageLight: '#008000',
    badgeWpp: '#aae8aa',
    rigthMesageDark: '#1a534c',
    checked: '#00F0FF',
    opacity: '#00000080',
    primaryContainer: '#c40808',
    pending: '#ffce32',
    positive: '#489766',
    inHouse: '#35C658',
    consulta: '#EEC218',
    postVenta: '#986AFB',
    confirmadas: '#03B2BD'
  },
  dark: {
    ...defaultConfig.tokens.colors,
    text: '#e6e6e6',
    tint: tintColorDark,
    textTint: '#ff8a8a',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    border: '#252525',
    backgroundActive: '#ffffff33',
    primary: '#cc3030',
    transparent: 'transparent',
    secondary: '#ffffff44',
    secondaryLight: '#ffffff88',
    onPrimary: '#fbcdcd',
    onSecondary: '#ffffff44',
    surface: '#FFF',
    success: '#00e676',
    successDarken: '#00f15a',
    successBackgound: '#2e5d46',
    indicatorTabs: '#FFB4A8',
    disabled: '#9b9b9b',
    background: '#272B2B',
    background2: '#191C1D',
    backgroundLight: '#E8E8E8',
    backgroundNavigationPxsol: '#1d1f1f',
    backgroundActiveNavigationPxsol: '#ffffff33',
    backgroundActiveNavigationPxsolLight: '#0f1621',
    backgroundSecondary: '#101415',
    backgroundSecondaryLight: '#FFF',
    backgroundBottomCustom: '#272B2B',
    backgroundSecondaryNavigationPxsol: '#080c12',
    backgroundExpired: '#D2F4EA',
    backgroundOpacity: '#00000066',
    backgroundIconWpp: '#25D366',
    header: '#0f1621',
    links: '#5baef0',
    blanco: '#FFFFFF',
    negro: '#000000',
    badgeSecondary: '#656565',
    iconos: '#8696a0',
    iconosDarken: '#3a3a3a',
    info: '#0099b8',
    infoDarken: '#05457e',
    infoOpacity: '#0099b8b0',
    bgInfo: '#332e3a40',
    error: '#f44336',
    errorLight: '#6d3030',
    errorDarken: '#ff2a2a',
    pxsol: '#cc3030',
    pxsol500: '#6c1b1b',
    pxsol600: '#402121',
    pxsolOpacity: '#cc303020',
    pxsolDarken: '#7c1e1f',
    gris: '#A6ABAF',
    warning: '#ffdc69',
    warningDarken: '#fb9c00',
    rigthMessage: '#006b62e0',
    rightMessageLight: '#008000',
    badgeWpp: '#aae8aa',
    rigthMesageDark: '#1a534c',
    checked: '#00F0FF',
    borderLight: '#dfdfdf',
    opacity: '#00000080',
    primaryContainer: '#c40808',
    pending: '#ffce32',
    positive: '#489766',
    inHouse: '#35C658',
    consulta: '#EEC218',
    postVenta: '#986AFB',
    confirmadas: '#03B2BD'
  }
};
