import React, { memo, Suspense } from 'react';
import { Center, ModalBackdrop, ModalContent, ModalHeader } from '@gluestack-ui/themed';

import { Modal } from '@gluestack-ui/themed';
import { Heading } from '@gluestack-ui/themed';
import { ModalBody } from '@gluestack-ui/themed';
import { ModalFooter } from '@gluestack-ui/themed';
import MainCta from '@/atoms/MainCta';
const PopoverChannels = React.lazy(
  () => import('@/components/partials/newConverExternal/PopoverChannels')
);
import { RootState, useAppSelector } from '@/redux/store';
import translate from '@/helpers/traslations';
import { THView, TText, TVView } from '@/components/themed';

const ModalInitConver = ({
  showModal,
  setShowModal,
  handleAcceptConversation,
  loading,
  callbackOnClose = null,
  initWith
}) => {
  const teamId = useAppSelector((state: RootState) => state.user.team.id);
  const popoverChannel = useAppSelector((state: RootState) => state.settings.popoverChannel);

  const handleAccetp = () => {
    if (popoverChannel) return;
    handleAcceptConversation({ part: null, channel: null });
  };

  return (
    <Center>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          callbackOnClose && callbackOnClose();
        }}
      >
        <ModalBackdrop />
        <ModalContent height={300} backgroundColor="$background">
          <ModalHeader>
            <Heading size="lg">{`${translate('messages.initConversation')}`}</Heading>
          </ModalHeader>
          <ModalBody>
            <TVView
              style={{
                gap: 8
              }}
            >
              <TText>{`${translate('labelCreateConver')} ${initWith}`}</TText>
              <Suspense fallback={<THView />}>
                <PopoverChannels teamId={teamId} />
              </Suspense>
            </TVView>
          </ModalBody>
          <ModalFooter>
            <THView
              style={{
                alignItems: 'center'
              }}
            >
              <MainCta
                variant="outline"
                action="secondary"
                labelId="actions.cancel"
                buttonSize="sm"
                onPress={() => {
                  setShowModal(false);
                  callbackOnClose && callbackOnClose();
                }}
              />

              <MainCta
                loading={loading}
                disabled={loading}
                labelId="actions.create"
                buttonSize="sm"
                onPress={handleAccetp}
              />
            </THView>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  );
};

export default memo(ModalInitConver);
