import {
  FormControlError,
  FormControlErrorText,
  FormControlHelper,
  FormControlHelperText,
  FormControlLabel,
  Input,
  InputField,
  FormControlErrorIcon,
  FormControl
} from '@gluestack-ui/themed';
import React from 'react';
import { AlertCircle } from '@/assets/icons/Icons';
import { InputTextFormProps } from './interfaces';
import { TText, TView } from '@/components/themed';

const InputTextForm = ({
  label,
  type = 'text',
  helperText = '',
  placeholder,
  errorText,
  isDisabled = false,
  isInvalid = false,
  isReadOnly = false,
  isRequired = false,
  onChange,
  value,
  bgColor,
  keyboardType = 'default',
  inputSlotLeft = null,
  inputSlotRight = null
}: InputTextFormProps) => {
  return (
    <TView style={{ width: '100%', flex: 1 }}>
      <FormControl
        size="md"
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        isReadOnly={isReadOnly}
        isRequired={isRequired}
      >
        <FormControlLabel mb="$1" gap="$1">
          <TText size="base">{label}</TText>
        </FormControlLabel>
        <Input
          bg={`$${bgColor}`}
          style={{
            alignItems: 'center',
            paddingHorizontal: 4
          }}
        >
          {inputSlotLeft}
          <InputField
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            fontSize="$md"
            keyboardType={keyboardType}
            style={{ paddingHorizontal: 0 }}
          />
          {inputSlotRight}
        </Input>
        <FormControlHelper>
          <FormControlHelperText>{helperText}</FormControlHelperText>
        </FormControlHelper>
        <FormControlError>
          <FormControlErrorIcon as={AlertCircle} />
          <FormControlErrorText>{errorText}</FormControlErrorText>
        </FormControlError>
      </FormControl>
    </TView>
  );
};

export default React.memo(InputTextForm);
