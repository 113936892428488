import React, { useEffect, useMemo } from 'react';
import { enableScreens } from 'react-native-screens';
import { RouteProp, useFocusEffect, useRoute } from '@react-navigation/native';
import CustomDrawerComponent from '@/routers/CustomDrawer/CustomDrawerComponent';
import { Spinner } from '@gluestack-ui/themed';
import LayoutConversationStack from '../layout/LayoutConversationStack';
import SidebarFilters from '../components/sidebar/sidebarFilters/SidebarFilters';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { setType } from '../redux/slices/filters';
import { filtersBase } from '../utils/filters';

import { IS_WEB_PC, getClientsFilters, verifyIsNotNativeDevice } from '../utils/functions';

import { Filters } from '@/redux/slices/filters/interfaces';
import { useCollapsed } from '@/providers/CollpasedProvider';
import { TABLET_SCREEN, WIDTH_SIDEBAR_COLLAPSED } from '@/utils/globales';
import { StackScreenProps } from '@react-navigation/stack';
import { RootChatStackParams, RootStackParams } from '@/routers/interfaces';
import { ConversationProvider } from '@/providers/ConversationProvider';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import ChatStackMobile from '@/layout/mobile/ChatStackMobile';

enableScreens();

function ChatStack(routeChat: StackScreenProps<RootChatStackParams>) {
  const routes = useRoute<RouteProp<RootStackParams>>();
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const [filterByName, setFiltersByName] = React.useState<Filters[]>(filtersBase);

  const dispatch = useAppDispatch();
  const { collapsed } = useCollapsed();

  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  useEffect(() => {
    if (routeChat?.route?.name) {
      const filterToGetClient = IS_WEB_PC
        ? filters
        : {
            assignee: filters?.assignee,
            labels: filters?.labels
          };
      const result = getClientsFilters(filterToGetClient, routeChat?.route?.name);
      setFiltersByName(result);
    }
  }, [filters, routeChat?.route?.name]);

  useFocusEffect(
    React.useCallback(() => {
      // Código a ejecutar cuando la pestaña de chat gana el foco
      dispatch(setType(routeChat?.route?.name.toString().toLowerCase()));
    }, [])
  );

  const routesFilter = useMemo(() => {
    if (filterByName?.length > 0) {
      return filterByName.map((filter, index) => ({
        id: index?.toString(),
        name: filter?.idString,
        component: LayoutConversationStack,
        initialParams: {
          filter: filter?.idString,
          team: routeChat?.route?.params?.team,
          id: routeChat?.route?.params?.id
        },
        options: {
          headerShown: !isPc,
          title: filter?.name ?? 'Filtros'
        }
      }));
    }
    return [];
  }, [filterByName, isPc, routeChat]);

  return routesFilter?.length > 0 ? (
    isPc ? (
      <ConversationProvider>
        <CustomDrawerComponent
          DrawerSidebar={SidebarFilters}
          routes={routesFilter}
          drawePermanent={!!verifyIsNotNativeDevice()}
          maxWidthSidebar={
            verifyIsNotNativeDevice() ? (collapsed ? WIDTH_SIDEBAR_COLLAPSED : 175) : '100%'
          }
          borderTopLeft={10}
        />
      </ConversationProvider>
    ) : (
      <ChatStackMobile {...routeChat} />
    )
  ) : (
    <Spinner size="small" color="$pxsol" />
  );
}

export default React.memo(ChatStack);
