import React from 'react';
import { Tooltip, TooltipContent, TooltipText } from '@gluestack-ui/themed';
import { View } from 'react-native';

type Tplacement =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'top left'
  | 'top right'
  | 'bottom left'
  | 'bottom right'
  | 'right top'
  | 'right bottom'
  | 'left top'
  | 'left bottom';

function CustomTooltip({
  children,
  title,
  placement = 'top'
}: {
  children: React.ReactNode;
  title: string;
  placement?: Tplacement;
}) {
  return (
    <Tooltip
      key={title}
      zIndex={99999999999}
      placement={placement}
      closeOnClick={true}
      trigger={(triggerProps: ({ ref, onHoverIn, onHoverOut }: any, { open }: any) => void) => {
        return <View {...triggerProps}>{children}</View>;
      }}
    >
      <TooltipContent bg="$background">
        <TooltipText maxWidth="$48" fontSize="$xs">
          {title}
        </TooltipText>
      </TooltipContent>
    </Tooltip>
  );
}

export default React.memo(CustomTooltip);
