import React, { memo, useMemo, useState } from 'react';
import AcordionCustom from '@/atoms/AcordionCustom';
import { Team } from '@/types/typesRedux/interfaces';
import RenderTeam from './RenderTeam';

const RenderCompanys = ({ item, route, onPressItem }) => {
  return (
    <AcordionCustom
      key={item?.id_company}
      idString={item?.company_name}
      defaultOpen={false}
      tranlate={false}
      titleBold
      bgColor="$transparent"
      sx={{
        minWidth: 400,
        width: '100%',
        ':hover': {
          background: 'red'
        }
      }}
    >
      {item?.teams?.length > 0 &&
        item?.teams?.map((team: Team) => {
          return <RenderTeam key={team?.id} item={team} onPressItem={onPressItem} route={route} />;
        })}
    </AcordionCustom>
  );
};

export default memo(RenderCompanys);
