export enum TPlacementMenu {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
  topLeft = 'top left',
  topRight = 'top right',
  bottomLeft = 'bottom left',
  bottomRight = 'bottom right',
  rightTop = 'right top',
  rightBottom = 'right bottom',
  leftTop = 'left top',
  leftBottom = 'left bottom'
}

export enum TModeGenerateResponse {
  TONE_SHIFT_PROFESSIONAL = 'TONE_SHIFT_PROFESSIONAL',
  TONE_SHIFT_CASUAL = 'TONE_SHIFT_CASUAL',
  TONE_SHIFT_FRIENDLY = 'TONE_SHIFT_FRIENDLY',
  REWRITE = 'REWRITE',
  SHORTEN = 'SHORTEN',
  EXTEND = 'EXTEND'
}
