// persist.web.ts
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import rootReducer from './rootReducer';

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'pxsol_conversations',
  version: 4.2,
  storeName: 'keyvaluepairs',
  description: 'Conversations database'
});

const peristCnfig = {
  key: 'root',
  storage: localForage,
  whitelist: [
    'templates',
    'user',
    'theme',
    'participants',
    'messages',
    'conversations',
    'contacts',
    'fragments',
    'filters',
    'labels',
    'settings'
  ]
};

const persistedReducer = persistReducer(peristCnfig, rootReducer);

export default persistedReducer;
