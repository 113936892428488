import React, { memo, useEffect, useMemo } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import CustomDrawerComponent from '@/routers/CustomDrawer/CustomDrawerComponent';
import { enableScreens } from 'react-native-screens';
import { Spinner } from '@gluestack-ui/themed';

import SidebarFilters from '../components/sidebar/sidebarFilters/SidebarFilters';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { setType } from '../redux/slices/filters';

import { getClientsFilters } from '../utils/functions';
import { ALL, filtersBase } from '@/utils/filters';
import { Filters } from '@/redux/slices/filters/interfaces';
import LayoutConversationStack from '../layout/LayoutConversationStack';
import { useCollapsed } from '@/providers/CollpasedProvider';
import { TABLET_SCREEN, WIDTH_SIDEBAR_COLLAPSED } from '@/utils/globales';
import { RootStackParams } from '@/routers/interfaces';
import { StackScreenProps } from '@react-navigation/stack';

import { ConversationProvider } from '@/providers/ConversationProvider';
import ClientStackMobile from '../layout/mobile/ClientStackMobile';
import { useMediaQuery } from '@/hooks/useMediaQuery';

enableScreens();
export const drawerClientRef = React.createRef();

function Clients(routeClient: StackScreenProps<RootStackParams>) {
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const dispatch = useAppDispatch();

  const [filterByName, setFiltersByName] = React.useState<Filters[]>([]);

  const { collapsed } = useCollapsed();

  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  useEffect(() => {
    if (routeClient?.route?.name) {
      const result = getClientsFilters(filters, routeClient?.route?.name);
      setFiltersByName(result);
    }
  }, [filters, routeClient?.route?.name]);

  useFocusEffect(
    React.useCallback(() => {
      dispatch(setType(routeClient?.route?.name.toLowerCase()));
    }, [])
  );

  const routesFilter = useMemo(() => {
    if (filterByName?.length > 0) {
      return filterByName.map((filter, index) => ({
        id: index?.toString(),
        name: filter?.idString,
        component: LayoutConversationStack,
        initialParams: {
          filter: filter?.idString,
          team: routeClient?.route?.params?.team,
          id: routeClient?.route?.params?.id
        },
        options: {
          headerShown: !isPc,
          title: filter?.name ?? 'Filtros'
        }
      }));
    }
    return [];
  }, [filterByName, isPc, routeClient]);

  return isPc ? (
    routesFilter?.length > 0 && (
      <ConversationProvider>
        <CustomDrawerComponent
          initialRouteName={ALL}
          DrawerSidebar={SidebarFilters}
          routes={routesFilter}
          drawePermanent={!!isPc}
          maxWidthSidebar={isPc ? (collapsed ? WIDTH_SIDEBAR_COLLAPSED : 175) : '100%'}
          borderTopLeft={10}
        />
      </ConversationProvider>
    )
  ) : filtersBase?.length > 0 ? (
    <ClientStackMobile {...routeClient} />
  ) : (
    <Spinner size="small" color="$pxsol" />
  );
}

export default memo(Clients, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
