import React, { useMemo } from 'react';
import TextFormatter from '@/atoms/TextFormatter';

import { RootState, useAppSelector } from '@/redux/store';
import { HeaderMessageComponentInterface } from './interfaces';
import { PlatformsEnum } from '@/types/enums/conversation';
import { THView } from '@/components/themed';
import MemoizedAvatarCard from '@/atoms/AvatarCard';
import { StyleSheet } from 'react-native';
import ChevronMessage from '@/atoms/message/ChevronMessage';

const HeaderMessageComponent = ({
  name,
  text,
  avatar,
  messageId,
  conversationId,
  showIconChevron,
  setDataBc
}: HeaderMessageComponentInterface) => {
  const platform = useAppSelector(
    (state: RootState) => state.conversations.conversations?.[conversationId]?.platform
  );

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          gap: 4,
          paddingVertical: 4
        },
        button: {
          width: 30,
          height: 30,
          borderRadius: 100,
          justifyContent: 'center',
          alignItems: 'center'
        }
      }),
    []
  );

  return (
    <THView bgColor="transparent" style={styles.container}>
      <MemoizedAvatarCard picture={avatar} width="$6" name={name} />
      <TextFormatter bold numberOfLines={1} color="text">
        {text}
      </TextFormatter>
      {showIconChevron && platform !== PlatformsEnum.internal && (
        <ChevronMessage
          setDataBc={setDataBc}
          conversationId={conversationId}
          messageId={messageId}
        />
      )}
    </THView>
  );
};

export default React.memo(HeaderMessageComponent, (prevProps, nextProps) => {
  return (
    prevProps.name === nextProps.name &&
    prevProps.text === nextProps.text &&
    prevProps.avatar === nextProps.avatar &&
    prevProps.messageId === nextProps.messageId &&
    prevProps.conversationId === nextProps.conversationId &&
    prevProps.showIconChevron === nextProps.showIconChevron &&
    prevProps.setDataBc === nextProps.setDataBc
  );
});
