import React, { memo, useMemo } from 'react';

import SoundNotifications from '@/components/partials/notifications/SoundNotifications';
import ChannelsNotifications from '@/components/partials/notifications/ChannelsNotifications';
import BrowserNotifications from '@/components/partials/notifications/BrowserNotifications';
import { ThemedScrollView } from '@/components/themed';
import AssignedNotification from '@/components/partials/notifications/AssignedNotification';
import { StyleSheet } from 'react-native';
import { RootState, useAppSelector } from '@/redux/store';

function SettingsNotifications() {
  const statusNotification = useAppSelector(
    (state: RootState) => state.user.notifications_config?.status_notification
  );

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        padding: 20,
        height: '100%'
      }
    });
  }, []);

  return (
    <ThemedScrollView
      showsVerticalScrollIndicator={false}
      bgColor="backgroundSecondary"
      style={styles.container}
    >
      <BrowserNotifications />
      {statusNotification && (
        <>
          <SoundNotifications />
          <AssignedNotification />
          <ChannelsNotifications />
        </>
      )}
    </ThemedScrollView>
  );
}

export default memo(SettingsNotifications);
